<template>
  <div>
    <crud-page
      :headers="headers"
      :options.sync="options"
      :items="items"
      :total="total"
      hide-new="yes"
      @edit="getOne"
      @delete="delItem"
    />
    <hmb-dialog
      v-model="dialog"
      :item="ccc"
      @toggle-activity="toggleActive"
    ></hmb-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('hmb')
import CrudPage from '../components/CrudPage'
import HmbDialog from '../components/hmb/HmbDialog.vue'

export default {
  name: 'CCC',
  components: {
    CrudPage,
    HmbDialog,
  },
  data() {
    return {
      dialog: false,
      options: {},
      headers: [
        {
          text: 'id',
          value: 'id',
        },
        {
          text: 'ccc',
          value: 'ccc',
        },
        {
          text: 'Booster License Id',
          value: 'boosterLicenseId',
        },
        {
          text: 'Order number',
          value: 'jobId',
        },
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['items', 'total', 'ccc']),
  },
  methods: {
    ...mapActions(['getItems', 'getItem', 'toggleActive', 'delItem']),
    getOne(id) {
      this.getItem(id).then(() => {
        this.dialog = true
      })
    },
  },
  watch: {
    options() {
      this.getItems(this.options)
    },
  },
}
</script>
