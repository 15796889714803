<template>
  <div>
    <v-list v-for="(menu, key) in filteredMenu" :key="key">
      <v-subheader>{{ menu.name }}</v-subheader>
      <v-list-item
        v-for="(menuItem, key) in menu.items"
        :key="key"
        link
        @click="$router.push(menuItem.link)"
      >
        <v-list-item-icon>
          <v-icon>{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ menuItem.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { menus } from '../menu.js'
export default {
  name: 'SideMenu',
  data() {
    return {
      menus: [...menus],
    }
  },
  computed: {
    filteredMenu() {
      let filtered = this.menus.filter((el) => this.checkGroup(el.groups))
      filtered.forEach((menu) => {
        menu.items = menu.items.filter((el) => this.checkGroup(el.groups))
      })
      return filtered
    },
  },
  methods: {
    checkGroup(groups) {
      if (groups.find((el) => el == this.$store.getters.group)) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
