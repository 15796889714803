<template>
  <div class="orders-bar">
    <v-app-bar-title>Заказы</v-app-bar-title>
    <v-spacer class="flex-grow-1"></v-spacer>

    <span class="local-time"><img src="003-.svg" />{{ timeMsk }} MSK</span>
    <span class="local-time"
      ><img src="001-european-union.svg" />{{ timeCet }} CET</span
    >
    <span class="local-time"
      ><img src="002-united-states.svg" />{{ timeEst }} EST</span
    >

    <v-spacer class="flex-grow-1"></v-spacer>
    <v-select
      class="status-select"
      dark
      dense
      :value="orderStatusesGrouped[0]"
      :items="orderStatusesGrouped"
      clearable
      label="Статус"
      flat
      solo-inverted
      hide-details
      @change="getOrdersByStatus"
    ></v-select>
    <v-btn depressed color="teal darken-4" @click="datepicker = !datepicker">
      <v-icon dark>mdi-calendar</v-icon> {{ datesToDisplay }}
    </v-btn>
    <v-btn v-if="submittedDates.length" depressed icon @click="resetDates()"
      ><v-icon>mdi-close</v-icon></v-btn
    >

    <v-text-field
      v-model="searchString"
      dense
      placeholder="Поиск"
      flat
      solo-inverted
      prepend-inner-icon="mdi-magnify"
      hide-details
      dark
      clearable
      @input="search"
    ></v-text-field>

    <v-dialog v-model="datepicker" width="300">
      <v-date-picker v-model="dates" range></v-date-picker>
      <v-btn color="primary" @click="submitDates()">Ok</v-btn>
      <v-btn @click="datepicker = !datepicker">Отмена</v-btn>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('orders')
import moment from 'moment'

export default {
  name: 'OrdersBar',
  data() {
    return {
      timeMsk: '',
      timeCet: '',
      timeEst: '',
      region: ['EU', 'US'],
      datepicker: false,
      dates: [],
      submittedDates: [],
      searchString: '',
      status: null,
    }
  },
  computed: {
    ...mapGetters(['orderStatusesGrouped']),
    datesToDisplay() {
      return this.submittedDates.length
        ? `${moment(this.submittedDates[0]).format('DD.MM.YY')} - ${moment(
            this.submittedDates[1]
          ).format('DD.MM.YY')}`
        : ''
    },
  },
  watch: {
    orderStatusesGrouped() {
      this.setFilterStatus(this.orderStatusesGrouped[0].value)
    },
  },
  methods: {
    ...mapActions([
      'getOrders',
      'setFilterStatus',
      'setFilterDates',
      'resetFilterOptions',
      'resetFilterDates',
      'searchOrders',
    ]),
    search() {
      if (!this.searchString) this.getOrders()
      else if (this.searchString.length > 3)
        this.searchOrders(this.searchString)
    },
    getOrdersByStatus(value) {
      this.resetFilterOptions()
      this.setFilterStatus(value)
      this.getOrders()
    },
    updateTime() {
      this.timeMsk = moment
        .utc()
        .utcOffset(60 * 3)
        .format('HH:mm')
      this.timeCet = moment
        .utc()
        .utcOffset(60 * 2)
        .format('HH:mm')
      this.timeEst = moment
        .utc()
        .utcOffset(60 * -5)
        .format('HH:mm')
    },
    submitDates() {
      this.submittedDates = this.dates
      this.setFilterDates(this.submittedDates)
      this.getOrders()
      this.datepicker = false
    },
    resetDates() {
      this.submittedDates = []
      this.resetFilterDates()
      this.getOrders()
    },
  },
  mounted() {
    this.updateTime()
    setInterval(() => {
      this.updateTime()
    }, 1000 * 60)
  },
}
</script>

<style>
.orders-bar {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.status-select {
  max-width: 150px;
}
.local-time {
  margin: 0 5px;
  white-space: nowrap;
  font-size: 0.8em;
}
.local-time img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
