const files = require.context('./modules', false, /\.js$/)
const modules = {}

files.keys().forEach((key) => {
  if (key !== './index.js') {
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
  }
})

export default modules
