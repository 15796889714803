import { api } from '@/store/helpers/http'

import {
  SET_COMPLAINTS_LIST,
  SET_COMPLAINTS_ID,
  SET_COMPLAINTS_DATE,
  SET_COMPLAINTS_ORDER,
  SET_COMPLAINTS_COMMENT,
  SET_COMPLAINTS_RESOLVED,
  SET_COMPLAINTS_CONTACTS,
} from '@/store/mutation-types'

export default {
  namespaced: true,
  state: {
    headers: [
      {
        text: 'Дата:',
        value: 'date',
      },
      { text: 'Id:', value: 'id' },
      { text: '№ заказа:', value: 'order' },
      { text: 'Жалоба:', value: 'comment' },
      { text: 'Статус:', value: 'resolved' },
      { text: 'Контакты:', value: 'contacts' },
      { text: 'Действия:', value: 'action' },
    ],
    complaints: [],
    id: 0,
    date: '',
    order: 0,
    comment: '',
    resolved: 0,
    contacts: '',
  },
  getters: {
    complaintsList: (state) =>
      state.complaints.map((item) => {
        item.resolved = !!item.resolved
        return item
      }),
  },
  mutations: {
    [SET_COMPLAINTS_LIST](state, payload) {
      state.complaints = payload
    },
    [SET_COMPLAINTS_ID](state, payload) {
      state.id = payload
    },
    [SET_COMPLAINTS_DATE](state, payload) {
      state.date = payload
    },
    [SET_COMPLAINTS_ORDER](state, payload) {
      state.order = payload
    },
    [SET_COMPLAINTS_COMMENT](state, payload) {
      state.comment = payload
    },
    [SET_COMPLAINTS_RESOLVED](state, payload) {
      state.resolved = payload
    },
    [SET_COMPLAINTS_CONTACTS](state, payload) {
      state.contacts = payload
    },
  },
  actions: {
    async getComplaintsList({ commit }) {
      try {
        const response = await api.get('/complaint')

        const { data: complaints } = response

        commit(SET_COMPLAINTS_LIST, complaints)
      } catch (error) {
        console.error(error)
      }
    },
    async getComplaint({ commit }, complainId) {
      try {
        const response = await api.get(`/complaint/${complainId}`)
        const { data: complaint } = response
        const { id, date, order, comment, resolved, contacts } = complaint

        commit(SET_COMPLAINTS_ID, id)
        commit(SET_COMPLAINTS_DATE, date)
        commit(SET_COMPLAINTS_ORDER, order)
        commit(SET_COMPLAINTS_COMMENT, comment)
        commit(SET_COMPLAINTS_RESOLVED, resolved)
        commit(SET_COMPLAINTS_CONTACTS, contacts)
      } catch (error) {
        console.error(error)
      }
    },
    setComplaintData({ commit }, complaint) {
      const { id, date, order, comment, resolved, contacts } = complaint

      commit(SET_COMPLAINTS_ID, id)
      commit(SET_COMPLAINTS_DATE, date)
      commit(SET_COMPLAINTS_ORDER, order)
      commit(SET_COMPLAINTS_COMMENT, comment)
      commit(SET_COMPLAINTS_CONTACTS, contacts)
      commit(SET_COMPLAINTS_RESOLVED, resolved)
    },
    async changeComplaint({ commit, dispatch, state }, payload) {
      try {
        const { complaint, resolvedState } = payload

        dispatch('setComplaintData', complaint)
        commit(SET_COMPLAINTS_RESOLVED, resolvedState)

        const { id, date, order, comment, resolved, contacts } = state
        const data = {
          id,
          date,
          order,
          comment,
          resolved,
          contacts,
        }

        await api.put(`/complaint/${id}`, data)
        await dispatch('getComplaintsList')
      } catch (error) {
        console.error(error)
      }
    },
  },
}
