<template>
  <v-container fluid style="position: relative">
    <v-card style="position: sticky; top: 48px; z-index: 5">
      <v-card-text>
        <v-row class="align-center">
          <v-col>
            <v-btn v-if="selectedItems.length" small @click="deleteItems">
              Удалить выбранные</v-btn
            >
          </v-col>
          <v-col>
            <event-selector v-model="selectedEvent" dense />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      v-model="selectedItems"
      show-select
      :loading="loading"
      multi-sort
      :options.sync="options"
      :headers="headers"
      :items="calendar"
      :server-items-length="total"
      item-key="id"
      class="mb-12"
      :footer-props="{
        'items-per-page-options': [25, 50, 100],
      }"
    >
      <template v-slot:item.time="{ item }">
        {{ formatDate(item.time) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <nobr>
          <v-btn icon @click="editItem(item.id)"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn icon @click="copyItem(item.id)"
            ><v-icon>mdi-content-copy</v-icon></v-btn
          >
          <v-btn icon @click="deleteItem(item.id)"
            ><v-icon>mdi-trash-can-outline</v-icon></v-btn
          >
        </nobr>
      </template>

      <template v-slot:item.boosterId="{ item }">
        {{ getBooster(item.boosterId) }}
      </template>

      <template v-slot:item.eventId="{ item }">
        {{ getEventName(item.eventId) }}
      </template>

      <template v-slot:item.slotsAvailable="{ item }">
        {{ item.slotsBusy }}/{{ item.slotsAvailable }}
      </template>

      <template v-slot:item.leather="{ item }">
        <div class="loot-slots">
          <nobr><img src="loot-04.jpg" /> {{ item.fabric }}</nobr>
          <nobr><img src="loot-03.jpg" /> {{ item.leather }}</nobr>
          <nobr><img src="loot-02.jpg" /> {{ item.chain }}</nobr>
          <nobr><img src="loot-01.jpg" /> {{ item.lat }}</nobr>
        </div>
      </template>

      <template v-slot:item.buyers="{ item }">
        <v-btn small @click="showByers(item.id)">Buyers</v-btn>
      </template>
    </v-data-table>

    <v-btn color="red" dark bottom right fab fixed @click="newItem()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" width="800">
      <v-card>
        <form @submit.prevent="save">
          <v-card-title class="teal darken-4 white--text">
            {{ item.id ? 'Редактирование записи' : 'Создание записи' }}
            <v-btn
              class="ml-auto"
              icon
              depressed
              color="white"
              @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>

          <v-card-text class="mt-5">
            <v-row>
              <v-col>
                <event-selector
                  v-if="item.eventId"
                  :key="item.id"
                  v-model="item.eventId"
                  label="Эвент"
                />
                <event-selector
                  v-else
                  :key="item.id"
                  v-model="multipleEvents"
                  multiple
                  label="Эвенты"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex">
                <v-btn
                  class="align-self-center mr-2"
                  icon
                  @click="openDatepicker()"
                  ><v-icon>mdi-calendar</v-icon></v-btn
                >
                <v-text-field
                  v-model="formattedDate"
                  v-mask="'##.##.####'"
                  type="text"
                  placeholder="DD.MM.YYYY"
                  label="Дата"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="time"
                  v-mask="'##:##'"
                  type="text"
                  placeholder="HH:mm"
                  label="Время"
                />
              </v-col>
              <v-col v-if="!isBooster">
                <booster-select :key="item.id" v-model="item.boosterId" />
              </v-col>
            </v-row>
            <h5>Slots</h5>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.slotsAvailable"
                  label="Общее количество слотов"
                />
              </v-col>
              <v-col>
                <v-text-field v-model="item.slotsBusy" label="Занято слотов" />
              </v-col>
            </v-row>

            <v-checkbox v-model="lootSlots" label="Слоты для лут трейдеров" />
            <v-row v-if="showLootSlots">
              <v-col>
                <v-text-field v-model="item.fabric" label="Ткань" />
              </v-col>
              <v-col>
                <v-text-field v-model="item.leather" label="Кожа" />
              </v-col>
              <v-col>
                <v-text-field v-model="item.chain" label="Кольчуга" />
              </v-col>
              <v-col>
                <v-text-field v-model="item.lat" label="Латы" />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn @click="dialog = !dialog">Close</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="byersDialog" width="800">
      <v-card>
        <v-card-title class="teal darken-4 white--text">
          Покупатели
          <v-btn class="ml-5" icon color="white" @click="byersToClipboard()"
            ><v-icon>mdi-content-copy</v-icon></v-btn
          >

          <v-btn
            class="ml-auto"
            icon
            depressed
            color="white"
            @click="byersDialog = !byersDialog"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text class="mt-5">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Номер заказа</th>
                  <th class="text-left">Battle Tag</th>
                  <th class="text-left">ID заказа</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in eventOrders" :key="item.order_id">
                  <td>{{ item.number }}</td>
                  <td>{{ item.battletag }}</td>
                  <td>{{ item.id }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="datepicker" width="300">
      <v-date-picker v-model="date" flat />
      <v-btn @click="setDate()">ОК</v-btn>
    </v-dialog>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { SNACK_SUCCESS } from '../store/mutation-types'
import moment from 'moment'
import {
  DATE_FORMAT,
  TIME_FORMAT,
  DATETIME_FORMAT,
  fromDB,
  toDB,
} from '@/helpers/dates'
import EventSelector from '../components/wizard/EventSelector.vue'
import BoosterSelect from '../components/BoosterSelect.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('calendar')
const ev = createNamespacedHelpers('events')

let emptyItem = {
  eventId: 0,
  time: '',
  slotsAvailable: 10,
  fabric: 0,
  leather: 0,
  chain: 0,
  lat: 0,
  slotsBusy: 0,
  boosterId: 0,
}

export default {
  name: 'Calendar',
  components: {
    EventSelector,
    BoosterSelect,
  },
  data() {
    return {
      date: '',
      time: '',
      multipleEvents: [],
      formattedDate: moment(new Date()).format('DD.MM.YYYY'),
      datepicker: false,
      item: { ...emptyItem },
      dialog: false,
      options: null,
      headers: [
        { text: 'Дата и время', value: 'time', sortable: true },
        { text: 'Бустер', value: 'boosterId', sortable: true },
        { text: 'Эвент', value: 'eventId', sortable: false },
        { text: 'Слоты', value: 'slotsAvailable', sortable: true },
        { text: 'Лут слоты', value: 'leather', sortable: false },
        { text: 'Клиенты', value: 'buyers', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
      lootSlotsCheckbox: false,
      byersDialog: false,
      selectedItems: [],
      selectedEvent: null,
      footerProps: {
        pagination: {
          itemsPerPage: [25, 50, 100],
        },
      },
    }
  },
  watch: {
    options() {
      this.setOptions(this.options)
      this.getItems()
    },
    selectedEvent() {
      this.setEventId(this.selectedEvent)
      this.getItems()
    },
  },
  computed: {
    ...mapGetters(['loading', 'calendar', 'eventOrders', 'total']),
    ...ev.mapGetters(['events']),
    isBooster() {
      return this.$store.getters.group === 'booster'
    },
    eventsSelect() {
      return this.events.map((el) => ({ text: el.name, value: el.id }))
    },
    boosters() {
      return this.$store.getters['booster/boosters']
    },
    boostersSelect() {
      return this.boosters.map((el) => ({ text: el.email, value: el.id }))
    },
    showLootSlots() {
      return (
        this.lootSlotsCheckbox ||
        this.item.leather > 0 ||
        this.item.chain > 0 ||
        this.item.lat > 0 ||
        this.item.fabric > 0
      )
    },
    lootSlots: {
      set(value) {
        this.lootSlotsCheckbox = value
      },
      get() {
        return this.showLootSlots
      },
    },
    byersList() {
      let list = ''
      this.eventOrders.forEach((el) => {
        let battle_tag = el.custom_field ? el.custom_field[1] : ''
        let unique_id = '&BB124'
        list += `${el.order_id}, ${battle_tag}, ${unique_id}\n\r`
      })
      return list
    },
  },
  methods: {
    ...mapActions([
      'getItems',
      'getItem',
      'addItem',
      'delItem',
      'updateItem',
      'setOptions',
      'getEventOrders',
      'setEventId',
    ]),
    formatDate(date) {
      return fromDB(date, DATETIME_FORMAT)
    },
    openDatepicker() {
      this.date = moment(this.formattedDate, DATE_FORMAT).format('YYYY-MM-DD')
      this.datepicker = true
    },
    setDate() {
      this.formattedDate = moment(this.date).format(DATE_FORMAT)
      this.datepicker = false
    },
    newItem() {
      this.dialog = true
      this.item = { ...emptyItem }
    },
    formatDates(time) {
      this.formattedDate = fromDB(time, DATE_FORMAT)
      this.time = fromDB(time, TIME_FORMAT)

      this.date = moment(time).format('YYYY-MM-DD')
    },
    editItem(id) {
      this.getItem(id).then((resp) => {
        this.item = resp.data
        this.formatDates(this.item.time)
        this.dialog = true
      })
    },
    copyItem(id) {
      this.getItem(id).then((resp) => {
        this.item = resp.data
        this.formatDates(this.item.time)
        delete this.item.id
        this.dialog = true
      })
    },
    deleteItem(id) {
      if (confirm('Вы уверены?')) {
        this.delItem(id).then(() => {
          this.$store.commit(SNACK_SUCCESS, 'Запись удалена')
          this.getItems()
        })
      }
    },
    deleteItems() {
      if (confirm('Вы уверены?')) {
        this.selectedItems.forEach((item) => {
          this.delItem(item.id).then(() => {
            this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
            if (this.selectedItems.length == 0) {
              this.getItems()
            }
          })
        })
      }
    },
    save() {
      /* сохраняем время в utc */
      this.item.time = toDB(
        `${this.formattedDate} ${this.time}`,
        DATETIME_FORMAT
      )

      if (this.item.id) {
        // если редактирование - сохраняем одну запись
        this.updateItem(this.item).then(() => {
          this.getItems()
          this.$store.commit(SNACK_SUCCESS, 'Запись сохранена')
          this.item = { ...emptyItem }
          this.dialog = false
        })
      } else {
        // если создание - сохраняем несколько, для каждого эвента.

        // перемножаем массив фракций на массив эвентов
        let eventsToCreate = []
        for (let eventId of this.multipleEvents) {
          eventsToCreate.push({
            eventId,
          })
        }

        let promises = eventsToCreate.map(({ eventId }) => {
          let _item = { ...this.item }
          _item.eventId = eventId
          return this.addItem(_item)
        })

        Promise.all(promises).then(() => {
          this.getItems()
          this.$store.commit(SNACK_SUCCESS, 'Записи созданы')
          this.item = { ...emptyItem }
          this.multipleEvents = []
          this.dialog = false
        })
      }
    },
    getBooster(id) {
      let booster = this.boosters.find((user) => user.id === id)
      return booster ? booster.name : ''
    },
    getEventName(id) {
      let event = this.events.find((event) => event.id === id)
      return event ? event.name : ''
    },
    byersToClipboard() {
      this.byersDialog = false
      setTimeout(() => {
        this.$clipboard(this.byersList)
      }, 500)
    },
    showByers(eventId) {
      this.getEventOrders(eventId).then(() => {
        this.byersDialog = true
      })
    },
  },
  mounted() {
    this.$store.dispatch('events/getItems', { page: 1, itemsPerPage: 999 })
    this.$store.dispatch('booster/getItems')
    //      this.getItems()
  },
}
</script>

<style>
.loot-slots img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
.loot-slots nobr {
  margin-right: 5px;
}
</style>
