<template>
  <v-list-item :class="['item', { checked: checked }]" @click="$emit('click')">
    <v-list-item-avatar>
      <v-img :src="image"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ name }}</v-list-item-title>
      <v-list-item-subtitle
        >id: {{ id }}, price: {{ price }}</v-list-item-subtitle
      >
    </v-list-item-content>

    <v-list-item-action>
      <v-icon v-if="checked" color="green lighten-1">mdi-check</v-icon>
      <v-icon v-if="selected">mdi-cancel</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'GameItem',
  props: ['id', 'image', 'name', 'price', 'checked', 'selected'],
}
</script>

<style scoped>
.item {
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.item:hover {
  background-color: #f5f5f5;
}
.item.checked {
  border: 2px solid green;
}
</style>
