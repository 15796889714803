<template>
  <v-container>
    <div v-if="wizard == 'product'">
      <v-row class="align-center">
        <v-col>
          <game-selector
            :key="gameKey"
            v-model="product"
            @input="
              eventId = 0
              eventKey++
            "
          />
        </v-col>
        <v-col class="shrink"> или </v-col>
        <v-col>
          <event-selector
            :key="eventKey"
            v-model="eventId"
            multiple
            @input="
              product = {}
              gameKey++
            "
          />
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col> &nbsp; </v-col>
        <v-col class="text-right">
          <v-btn color="primary" @click="chooseProductOrEvent()">Далее</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="wizard == 'options'">
      <v-card class="mb-2">
        <game-item
          :id="product.id"
          :key="product.id"
          :name="product.name"
          :price="product.price"
          :image="product.image"
        />
      </v-card>

      <options-selector
        :id="product.id"
        :key="optionsKey"
        v-model="selectedOptions"
      />

      <v-row class="mt-4">
        <v-col>
          <v-btn @click="restart()">Назад</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn color="primary" @click="wizard = 'timetable'">Далее</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="wizard == 'timetable'">
      <v-row>
        <v-col>
          <v-date-picker v-model="dates" range></v-date-picker>
        </v-col>
        <v-col>
          <h5>Настройки времени</h5>
          <v-text-field
            v-model="startTime"
            v-mask="'##:##'"
            label="Время с"
            placeholder="HH:mm"
          />
          <v-text-field
            v-model="endTime"
            v-mask="'##:##'"
            label="Время по"
            placeholder="HH:mm"
          />
          <v-select
            v-model="step"
            :items="[10, 15, 20, 30, 60]"
            label="Интервал, минут"
          />
        </v-col>
        <v-col>
          <h5>Slots</h5>
          <v-text-field v-model="slots" label="Всего слотов" />
        </v-col>
        <v-col>
          <h5>Loot trades slots (optional)</h5>
          <v-text-field v-model="fabric" label="Ткань" />

          <v-text-field v-model="leather" label="Кожа" />

          <v-text-field v-model="chain" label="Кольчуга" />

          <v-text-field v-model="lat" label="Латы" />
        </v-col>

        <v-col>
          <booster-select v-model="boosterId" />
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-btn @click="restart()">Назад</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn color="primary" @click="generateTimetable()">Далее</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="wizard == 'confirm'">
      <v-row>
        <v-col v-if="eventId">
          <h3>Расписание будет создано для</h3>
          <event-selector v-model="eventId" multiple />

          <h3>Booster</h3>
          <booster-select v-model="boosterId" />
        </v-col>
        <v-col v-else>
          <h3>Будут созданы эвенты:</h3>
          <ul>
            <li v-for="(name, key) in eventNames" :key="key">{{ name }}</li>
          </ul>
        </v-col>
        <v-col>
          <h3>Будет создано расписание</h3>
          <div>
            slots: {{ slots }}, ткань: {{ fabric }}, кожа {{ leather }},
            кольчуга: {{ chain }}, латы: {{ lat }}
            <dl v-for="(item, key) in timetableByDates" :key="key" two-line>
              <dt>
                <h4>{{ item.dayOfWeek }}:</h4>
              </dt>
              <dd>
                <v-chip
                  v-for="time in item.time"
                  :key="time"
                  class="ma-1"
                  close
                  close-icon="mdi-close"
                  @click:close="removeFromTimetable(item.day, time)"
                  >{{ time }}</v-chip
                >
              </dd>
            </dl>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-btn @click="wizard = 'timetable'">Назад</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn color="primary" @click="go()">Создать</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="wizard == 'progress'">
      <v-icon v-if="progress < 100" x-large color="warning"
        >mdi-timer-sand</v-icon
      >
      <v-icon v-else x-large color="success">mdi-check-circle-outline</v-icon>
      <span class="mt-4">Выполнено {{ progress }}%</span>
      <v-progress-linear class="mt-4" :value="progress"></v-progress-linear>

      <div v-if="progress == 100" class="mt-4">
        <v-btn color="primary" @click="$router.push('calendar')"
          >Перейти к эвентам</v-btn
        >
        <v-btn @click="restart()">Начать сначала</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { toDB } from '@/helpers/dates'
import GameSelector from '../components/wizard/GameSelector.vue'
import OptionsSelector from '../components/wizard/OptionsSelector.vue'
import EventSelector from '../components/wizard/EventSelector.vue'
import GameItem from '../components/wizard/GameItem.vue'
import BoosterSelect from '../components/BoosterSelect.vue'
import { api } from '@/store/helpers/http'
import { API_CALENDAR } from '@/api'

export default {
  name: 'Wizard',
  components: {
    GameSelector,
    OptionsSelector,
    EventSelector,
    GameItem,
    BoosterSelect,
  },
  data() {
    return {
      boosterId: 0,
      boosterName: '',
      wizard: 'product',
      eventId: [],
      dates: [],
      startTime: '',
      endTime: '',
      product: {},
      selectedOptions: [],
      fabric: 0,
      lat: 0,
      leather: 0,
      chain: 0,
      slots: 0,
      step: '',
      eventsCreated: 0,
      timesCreated: 0,
      eventFieldsCreated: 0,
      optionsKey: 0,
      showTimetable: false,
      timetable: [],
      gameKey: 0,
      eventKey: 0,
    }
  },
  computed: {
    progress() {
      if (this.eventId) {
        return Math.floor((100 * this.timesCreated) / this.timesTotal)
      } else {
        return Math.floor(
          (100 *
            (this.eventsCreated +
              this.timesCreated +
              this.eventFieldsCreated)) /
            (this.timesTotal + this.fieldsTotal + this.events.length)
        )
      }
    },
    timesTotal() {
      return this.eventId.length > 0
        ? this.timetable.length * this.eventId.length
        : this.timetable.length
    },
    fieldsTotal() {
      return this.events[0]?.length * this.events.length
    },
    options() {
      return this.selectedOptions.map((el) => {
        let res = []
        el.values
          .filter((v) => v.selected === true)
          .forEach((v) => {
            res.push({
              name: el.name,
              value: v.name,
            })
          })
        return res
      })
    },
    events() {
      let options = this.options.filter((item) => item.length)

      let events = []

      // если выбрано больше одной опции делаем комбинации
      if (options.length > 1) {
        for (let i = 1; i < this.options.length; i++) {
          if (this.options[i].length) {
            events = this.multiply(
              events.length ? events : this.options[i - 1],
              this.options[i]
            )
          }
        }
        return events
        // если опция всего одна ,тогда каждое значение опции - эвент
      } else {
        options[0].forEach((option_value) => {
          events.push([option_value])
        })

        return events
      }
    },

    eventNames() {
      return this.events.map((event) => {
        let eventName = this.product.name
        event.forEach(({ value }) => {
          eventName += ` | ${value}`
        })
        return eventName
      })
    },

    timetableByDates() {
      let days = []
      let day = []
      for (let i = 0; i < this.timetable.length; i++) {
        if (this.timetable[i].getDate() === this.timetable[i + 1]?.getDate()) {
          day.push(this.timetable[i].toLocaleTimeString())
        } else {
          day.push(this.timetable[i].toLocaleTimeString())
          days.push({
            day: this.timetable[i].toLocaleDateString(),
            dayOfWeek: this.timetable[i].toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric'}),
            time: [...day],
          })
          day = []
        }
      }
      return days
    },
  },
  methods: {
    async go() {
      this.wizard = 'progress'
      if (this.eventId.length > 0) {
        for (const eventId of this.eventId) {
          await this.createTimetable(eventId)
        }
      } else {
        this.createEvents()
      }
    },
    restart() {
      this.product = {}
      this.selectedOptions = []
      this.optionsKey++
      this.wizard = 'product'
      this.eventsCreated = 0
      this.timesCreated = 0
      this.eventFieldsCreated = 0
    },
    chooseProductOrEvent() {
      if (this.eventId) {
        this.wizard = 'timetable'
        return
      }

      if (this.product?.id) {
        this.wizard = 'options'
        return
      }
    },
    multiply(a, b) {
      let res = []
      a.forEach((v) => {
        b.forEach((w) => {
          let comb = []
          if (!Array.isArray(v)) {
            comb.push(v)
          } else {
            v.forEach((x) => {
              comb.push(x)
            })
          }
          comb.push(w)
          res.push(comb)
        })
      })
      return res
    },
    createEvents() {
      this.events.forEach((event) => {
        let eventName = this.product.name
        event.forEach(({ value }) => {
          eventName += ` | ${value}`
        })

        this.$store
          .dispatch('events/addItem', {
            productId: this.product.id,
            name: eventName,
          })
          .then((resp) => {
            this.eventsCreated++
            let eventId = resp.data.id

            // создаём поля эвенета
            event.forEach((field) => {
              this.$store
                .dispatch('eventfields/addEventFieldByEvent', {
                  eventId: eventId,
                  item: field,
                })
                .then(() => {
                  this.eventFieldsCreated++
                })
            })

            this.createTimetable(eventId)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    async createTimetable(eventId) {
      // создаём расписание для эвента
      for (const time of this.timetable) {
        await api
          .post(API_CALENDAR, {
            eventId: eventId,
            time: toDB(time),
            slotsAvailable: this.slots,
            fabric: this.fabric,
            leather: this.leather,
            chain: this.chain,
            lat: this.lat,
            slotsBusy: 0,
            boosterId: this.boosterId,
          })
          .then(() => {
            this.timesCreated++
          })
      }
    },
    generateTimetable() {
      let timetable = []
      if (this.dates.length > 1) {
        let from = new Date(this.dates[0])
        let to = new Date(this.dates[1])
        if (from > to) {
          let tmp = from
          from = to
          to = tmp
        }
        for (let d = from; d <= to; d.setDate(d.getDate() + 1)) {
          let from = new Date(d)
          let to = new Date(d)

          let startTime = this.startTime.split(':')
          let endTime = this.endTime.split(':')

          from.setHours(parseInt(startTime[0]))
          to.setHours(parseInt(endTime[0]))

          from.setSeconds(parseInt(startTime[1]))
          to.setSeconds(parseInt(endTime[1]))

          for (let t = from; t < to; t.setMinutes(t.getMinutes() + this.step)) {
            timetable.push(new Date(t))
          }
        }
      }
      this.timetable = timetable
      this.wizard = 'confirm'
    },
    removeFromTimetable(day, time) {
      const date = new Date(`${day.split('.').reverse().join('-')}T${time}`)
      const index = this.timetable.findIndex(
        (item) => item.getTime() === date.getTime()
      )
      this.timetable.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.progress {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
