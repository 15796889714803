const vModelBinding = (props, postfix) => {
  const models = {}
  props.forEach((element) => {
    const { state, action } = element
    models[`${state}${postfix}`] = {
      get() {
        return this[state]
      },
      set(value) {
        this[action](value)
      },
    }
  })
  return models
}

export default vModelBinding
