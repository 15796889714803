<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col cols="6">
        <SignIn />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignIn from '@/components/SignIn'

export default {
  name: 'Authorization',
  components: {
    SignIn,
  },
}
</script>
