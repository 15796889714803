<template>
  <div class="booster-select">
    <div class="d-flex align-center">
      <v-text-field
        v-model="booster"
        label="Booster"
        :prefix="id && !hideId ? `id: ${id}` : ''"
        clearable
        @keyup="findBooster"
        @click:clear="
          id = null
          $emit('clear')
        "
      />
      <v-btn v-if="!hideAdd" icon @click="addBooster"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </div>
    <v-card v-if="showList && filteredBoosters.length" class="booster-list">
      <v-list>
        <v-list-item
          v-for="booster in filteredBoosters"
          :key="booster.id"
          link
          @click="setBooster(booster)"
          >{{ booster.name }}</v-list-item
        >
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('booster')

export default {
  name: 'BoosterSelect',
  props: ['value', 'boosterName', 'hideAdd', 'hideId', 'dontGet', 'items'],
  data() {
    return {
      id: this.value || null,
      booster: this.boosterName || '',
      showList: false,
    }
  },
  watch: {
    id() {
      this.$emit('input', this.id)
    },
  },
  computed: {
    ...mapGetters(['boosters']),
    filteredBoosters() {
      return this.boosters.filter(({ name }) => name.indexOf(this.booster) > -1)
    },
  },
  methods: {
    ...mapActions(['addItem', 'getItems']),
    setBooster(booster) {
      this.$emit('change', booster.id)
      this.id = booster.id
      this.booster = booster.name
      this.showList = false
    },
    findBooster() {
      this.id = null
      this.showList = true
    },
    addBooster() {
      this.addItem({
        name: this.booster,
      }).then((resp) => {
        this.id = resp.data.id
        this.showList = false
      })
    },
    setBoosterName() {
      if (this.id) {
        const b = this.boosters.find(({ id }) => id === this.id)
        if (b) {
          this.booster = b.name
        }
      }
    },
  },
  mounted() {
    if (!this.dontGet)
      this.getItems().then(() => {
        this.setBoosterName()
      })
    else this.setBoosterName()
  },
}
</script>

<style scoped>
.booster-select {
  position: relative;
}
.booster-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 199;
  width: 100%;
  max-height: 150px;
  overflow: auto;
}
</style>
