import { api } from '@/store/helpers/http'
import {
  SET_PAYOUTS_LIST,
  SET_PAYOUTS_TOTAL,
  SET_PAYOUTS_SUM,
} from '@/store/mutation-types'
import { API_PAYOUT, API_PAYOUT_CALCULATE } from '../../api'

export default {
  namespaced: true,
  state: {
    payouts: [],
    total: -1,
    sum: 0,
  },
  getters: {
    payoutsList: (state) => state.payouts,
    total: (state) => state.total,
    sum: (state) => state.sum,
  },
  actions: {
    getSum({ commit }, options) {
      let params = options
        ? {
            ...options,
          }
        : {}

      return new Promise((res, rej) => {
        api
          .get(API_PAYOUT_CALCULATE, { params: params })
          .then((resp) => {
            commit(SET_PAYOUTS_SUM, resp.data.sum)
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    getItems({ commit }, options) {
      let params = options
        ? {
            ...options,
            'per-page': options.itemsPerPage,
          }
        : {}

      return new Promise((res, rej) => {
        api
          .get(API_PAYOUT, { params: params })
          .then((resp) => {
            commit(SET_PAYOUTS_LIST, resp.data)
            commit(SET_PAYOUTS_TOTAL, resp.headers['x-pagination-total-count'])
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    addItem(store, payout) {
      return new Promise((res, rej) => {
        api
          .post(API_PAYOUT, payout)
          .then((resp) => {
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    updateItem(store, payout) {
      return new Promise((res, rej) => {
        api
          .put(`${API_PAYOUT}/${payout.id}`, payout)
          .then((resp) => {
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    delItem(store, payout) {
      return new Promise((res, rej) => {
        api
          .delete(`${API_PAYOUT}/${payout.id}`)
          .then((resp) => {
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
  },
  mutations: {
    [SET_PAYOUTS_LIST](state, payload) {
      state.payouts = payload
    },
    [SET_PAYOUTS_TOTAL](state, total) {
      state.total = parseInt(total)
    },
    [SET_PAYOUTS_SUM](state, sum) {
      state.sum = parseFloat(sum)
    },
  },
}
