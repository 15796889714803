<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-select
          v-model="options.status"
          placeholder="Status"
          clearable
          :items="statuses"
          @click:clear="delete options.status"
        ></v-select>
      </v-col>
      <v-col>
        <booster-select
          v-model="options.booster"
          hide-add="true"
          @clear="delete options.booster"
        ></booster-select>
      </v-col>
      <v-col>
        Невыплачено всего: {{ sum }}
        <br />
        Сумма выбранных: {{ selectedTotal }}
        <br />
        <v-btn x-small size="sm" color="primary" @click="multiDialog = true"
          >Изменить выбранные</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      v-model="selected"
      :loading="isLoading"
      loading-text="Загрузка выплат..."
      no-data-text="Выплат нет"
      :options.sync="options"
      :headers="headers"
      :items="payoutsList"
      :server-items-length="total"
      multi-sort
      show-select
      show-group-by
      :footer-props="{
        'items-per-page-options': [50, 100, 150],
      }"
    >
      <template #item.payAmount="{ item }">
        {{ item.payAmount }} {{ item.payCurrency }}
      </template>

      <template #item.status="{ item }">
        <v-chip v-if="item.status" color="green">Выплачен</v-chip>
        <v-chip v-else color="red">Не выплачен</v-chip>
      </template>

      <template #item.actions="{ item }">
        <v-btn icon @click="showDialog(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn icon @click="del(item)"><v-icon>mdi-delete</v-icon></v-btn>
      </template>
    </v-data-table>

    <payout-dialog
      :key="payout.id"
      v-model="dialog"
      :item="payout"
      @itemSaved="getItems()"
    />

    <payout-dialog
      :key="JSON.stringify(selected)"
      v-model="multiDialog"
      multiple="true"
      :items="selected"
      @itemSaved="getItems()"
    />
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PayoutDialog from '../components/payouts/PayoutDialog.vue'
import BoosterSelect from '../components/BoosterSelect.vue'
const { mapGetters, mapActions } = createNamespacedHelpers('payouts')
import { SNACK_SUCCESS } from '@/store/mutation-types'

export default {
  name: 'Payouts',
  components: {
    PayoutDialog,
    BoosterSelect,
  },
  data() {
    return {
      headers: [
        { text: 'Id: ', value: 'id' },
        { text: 'Id заказа: ', value: 'externalId' },
        { text: 'booster: ', value: 'booster' },
        { text: 'date: ', value: 'date' },
        { text: 'Выплата: ', value: 'payAmount' },
        { text: 'screenshot: ', value: 'screenshot' },
        { text: 'status: ', value: 'status' },
        { text: '', value: 'actions' },
      ],
      isLoading: true,
      dialog: false,
      multiDialog: false,
      options: {},
      payout: {},
      selected: [],
      statuses: [
        { text: 'Выплачен', value: 1 },
        { text: 'Не выплачен', value: 0 },
      ],
    }
  },
  computed: {
    ...mapGetters(['payoutsList', 'total', 'sum']),
    selectedTotal() {
      const values = this.selected.map(({ payAmount }) => parseFloat(payAmount))
      if (values.length > 0) return values.reduce((a, c) => a + c)
      else return 0
    },
  },
  watch: {
    options() {
      this.loadItems()
    },
  },
  methods: {
    ...mapActions(['getItems', 'getSum', 'delItem']),
    showDialog(item) {
      this.payout = { ...item }
      this.dialog = true
    },
    del(item) {
      this.delItem(item).then(() => {
        this.$store.commit(SNACK_SUCCESS, 'Выплата удалена')
        this.getItems()
      })
    },
    loadItems() {
      this.isLoading = true
      this.isLoadingSum = true
      this.getItems({ ...this.options }).then(() => {
        this.isLoading = false
      })
      this.getSum({ booster: this.options.booster }).then(() => {
        this.isLoadingSum = false
      })
    },
  },
}
</script>

<style scoped></style>
