<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card>
      <v-form @submit.prevent="multiple ? saveAll() : savePayout()">
        <v-card-title class="teal darken-4 white--text">
          <span v-if="payout.id">Выплата №{{ payout.id }}</span>
          <span v-else>Новая выплата</span>
          <v-spacer />
          <v-btn
            v-if="!noClose"
            icon
            depressed
            color="white"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-switch
            v-model="payout.status"
            :label="payout.status ? 'Выплачен' : 'Не выплачен'"
          ></v-switch>

          {{ payout.externalId }}

          <div v-if="multiple">
            <div>Id заказов</div>
            <span v-for="po in items" :key="po.orderId"
              >{{ po.externalId }},
            </span>
            <div>Суммы выплат</div>
            <span v-for="po in items" :key="po.orderId"
              >{{ po.payAmount }},
            </span>
            <div>Итого</div>
            <span>{{ total }}</span>
          </div>
          <v-row v-else>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="payout.orderId" label="Id заказа" />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="payout.orderItemId"
                label="Id позиции в заказе"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <booster-select
                v-model="payout.boosterId"
                :booster-name="payout.booster"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="payout.screenshot"
                label="Скриншот выплаты"
              />
            </v-col>
          </v-row>
          <br />
          <br />
          <v-row v-if="!multiple">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="payout.payAmount"
                :suffix="payout.payCurrency"
                label="Сумма"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="payout.payCurrency"
                :items="currencies"
                label="Валюта"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" type="submit">Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import BoosterSelect from '@/components/BoosterSelect'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('payouts')
import { SNACK_SUCCESS } from '@/store/mutation-types'

const payout = {
  orderId: null,
  orderItemId: null,
  booster: '',
  status: false,
  screenshot: '',
  payAmount: 0,
  payCurrency: 'USD',
  externalId: '',
}

export default {
  name: 'PayoutDialog',
  components: {
    BoosterSelect,
  },
  props: ['value', 'item', 'noClose', 'multiple', 'items'],
  data() {
    return {
      payout: this.multiple ? this.items[0] : this.item || payout,
      dialog: this.value,
      currencies: ['EUR', 'USD'],
    }
  },
  computed: {
    total() {
      const total = this.items.map(({ payAmount }) => parseFloat(payAmount))
      if (total.length > 0) {
        return total.reduce((a, c) => a + c)
      } else {
        return 0
      }
    },
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    },
  },
  methods: {
    ...mapActions(['addItem', 'updateItem']),
    saveAll() {
      if (this.items.length && this.multiple) {
        Promise.all(
          this.items.map((payout) =>
            this.updateItem({
              ...payout,
              status: this.payout.status,
              screenshot: this.payout.screenshot,
            })
          )
        ).then(() => {
          this.$store.commit(SNACK_SUCCESS, 'Выплаты обновлена')
          this.$emit('itemSaved')
          this.dialog = false
        })
      }
    },
    savePayout() {
      if (this.payout.id) {
        this.updateItem(this.payout).then(() => {
          this.$store.commit(SNACK_SUCCESS, 'Выплата обновлена')
          this.$emit('itemSaved')
          this.dialog = false
        })
      } else {
        this.addItem(this.payout).then(() => {
          this.$store.commit(SNACK_SUCCESS, 'Выплата добавлена')
          this.$emit('itemSaved')
          this.dialog = false
        })
      }
    },
  },
}
</script>
