var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"options":_vm.options,"items":_vm.refunds,"headers":_vm.headers},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"ml-2",attrs:{"color":item.status === 1 ? 'green' : 'red'}},[_vm._v(" mdi-record ")])],1)]}},{key:"item.refundScreen",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":item.refundScreen}},[(/(.+)\.(png|jpe?g|webp)$/i.test(item.refundScreen))?_c('img',{attrs:{"src":item.refundScreen,"width":"200","alt":""}}):_c('span',[_vm._v(_vm._s(item.refundScreen))])])]}},{key:"item.userScreen",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":item.userScreen}},[(/(.+)\.(png|jpe?g|webp)$/i.test(item.userScreen))?_c('img',{attrs:{"src":item.userScreen,"width":"200","alt":""}}):_c('span',[_vm._v(_vm._s(item.userScreen))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"orange"},on:{"click":function($event){return _vm.openRefund(item.id, item.orderId)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(!item.comment.length)?_c('v-btn',{attrs:{"x-small":"","color":"gray","link":"","depressed":""},on:{"click":function($event){return _vm.openRefund(item.id, item.orderId)}}},[_vm._v(" Добавить ")]):_c('span',{staticClass:"comment-row",on:{"click":function($event){return _vm.openRefund(item.id, item.orderId)}}},[_vm._v(" "+_vm._s(item.comment)+" ")])]}}])}),(_vm.refund.orderItem)?_c('refund-dialog',{attrs:{"refund":_vm.refund.refundItem,"order":_vm.refund.orderItem,"dialog":_vm.dialog,"close":_vm.closeDialog},on:{"delete-refund":_vm.deleteRefundItem,"update-refund":_vm.updateRefundItem}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }