<template>
  <!-- TrustBox widget - List Filtered -->
  <div
    class="trustpilot-widget"
    data-locale="en-US"
    data-template-id="539ad998dec7e10e686debe0"
    data-businessunit-id="60203104c0f73e00011d7fea"
    data-style-height="500px"
    data-style-width="100%"
    data-theme="light"
  >
    <a
      href="https://www.trustpilot.com/review/gamingcy.com"
      target="_blank"
      rel="noopener"
      >Trustpilot</a
    >
  </div>
  <!-- End TrustBox widget -->
</template>

<script>
export default {
  name: 'Trustbox',
  mounted() {
    const script = document.createElement('script')
    script.setAttribute(
      'src',
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    )
    document.head.append(script)
  },
}
</script>

<style></style>
