<template>
  <v-container fluid>
    <v-data-table :options.sync="options" :items="refunds" :headers="headers">
      <template #item.status="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="item.status === 1 ? 'green' : 'red'" class="ml-2">
            mdi-record
          </v-icon>
        </div>
      </template>

      <template #item.refundScreen="{ item }">
        <a target="_blank" :href="item.refundScreen">
          <img
            v-if="/(.+)\.(png|jpe?g|webp)$/i.test(item.refundScreen)"
            :src="item.refundScreen"
            width="200"
            alt=""
          />
          <span v-else>{{ item.refundScreen }}</span>
        </a>
      </template>

      <template #item.userScreen="{ item }">
        <a target="_blank" :href="item.userScreen">
          <img
            v-if="/(.+)\.(png|jpe?g|webp)$/i.test(item.userScreen)"
            :src="item.userScreen"
            width="200"
            alt=""
          />
          <span v-else>{{ item.userScreen }}</span>
        </a>
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex">
          <v-btn icon color="orange" @click="openRefund(item.id, item.orderId)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>

      <template #item.comment="{ item }">
        <v-btn
          v-if="!item.comment.length"
          x-small
          color="gray"
          link
          depressed
          @click="openRefund(item.id, item.orderId)"
        >
          Добавить
        </v-btn>
        <span
          v-else
          class="comment-row"
          @click="openRefund(item.id, item.orderId)"
        >
          {{ item.comment }}
        </span>
      </template>
    </v-data-table>

    <refund-dialog
      v-if="refund.orderItem"
      :refund="refund.refundItem"
      :order="refund.orderItem"
      :dialog="dialog"
      :close="closeDialog"
      @delete-refund="deleteRefundItem"
      @update-refund="updateRefundItem"
    ></refund-dialog>
  </v-container>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapGetters } = createNamespacedHelpers('refunds')

import RefundDialog from '@/components/refunds/RefundDialog'

export default {
  name: 'Refunds',
  components: { RefundDialog },
  data() {
    return {
      headers: [
        { text: '№', value: 'id' },
        { text: '№ Заказа', value: 'orderId' },
        { text: 'Сумма', value: 'refundSum' },
        { text: 'Валюта', value: 'refundCurrency' },
        { text: 'Refund Screen', value: 'refundScreen' },
        { text: 'User Screen', value: 'userScreen' },
        { text: 'Статус', value: 'status' },
        { text: 'User', value: 'user' },
        { text: 'Действия', value: 'actions' },
        { text: 'Причина рефанда', value: 'comment' },
      ],
      refund: {
        refundItem: null,
        orderItem: null,
      },
      dialog: false,
      options: {},
    }
  },
  // мы смотрим изменение опций в таблице (пагинация, число элементов)
  // оно отрабатывает каждый раз когда мы что-то меняем
  // и при загрузке таблицы, значит mounted() хук не нужен.
  watch: {
    options() {
      this.getRefunds(this.options)
    },
  },
  computed: {
    ...mapGetters(['refunds']),
  },
  methods: {
    ...mapActions('refunds', [
      'getRefunds',
      'getRefund',
      'updateRefund',
      'deleteRefund',
    ]),
    ...mapActions('orders', ['getOrder']),
    openRefund(refundId, orderId) {
      this.getRefund(refundId).then((refundData) => {
        this.refund.refundItem = refundData.data

        this.getOrder(orderId).then((orderData) => {
          this.refund.orderItem = orderData.data.data
          this.openDialog()
        })
      })
    },
    updateRefundItem(updatedRefundItem) {
      this.updateRefund(updatedRefundItem).then(() => {
        this.closeDialog()
      })
    },
    deleteRefundItem(id) {
      if (confirm('Вы уверены?')) {
        this.deleteRefund(id).then(() => {
          this.closeDialog()
        })
      }
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.refund = {
        refundItem: null,
        orderItem: null,
      }
      this.dialog = false
    },
  },
}
</script>
