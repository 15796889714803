import {
  SET_USERS,
  SET_USERS_TOTAL,
  SET_GROUPS,
  SET_USER,
} from '../mutation-types'
import { api } from '@/store/helpers/http'
import { API_USERS, API_USER_GROUPS } from '@/api'

export default {
  namespaced: true,
  state: {
    total: -1,
    users: [],
    groups: [],
    user: {},
  },
  mutations: {
    [SET_USERS]: (state, data) => {
      state.users = data
    },
    [SET_USERS_TOTAL]: (state, total) => {
      state.total = parseInt(total)
    },
    [SET_GROUPS]: (state, data) => {
      state.groups = data
    },
    [SET_USER]: (state, user) => {
      state.user = user
    },
  },
  getters: {
    user: (state) => state.user,
    total: (state) => state.total,
    users: (state) => state.users,
    groups: (state) => state.groups,
    boosters: (state) => state.users.filter((user) => user.group === 3),
    usersToDisplay: (state) => {
      let res = []
      let groups = state.groups || []
      state.users.forEach((user) => {
        let item = { ...user, ...user.profile }
        if (groups.length)
          item.group = groups.find((item) => item.id === user.group).name
        res.push(item)
      })
      return res
    },
    groupsSelect: (state) => {
      let res = []
      state.groups.forEach((group) => {
        res.push({ text: group.name, value: group.id })
      })
      return res
    },
  },
  actions: {
    getGroups: ({ commit }, options) => {
      return new Promise((resolve, reject) => {
        let params = options
          ? {
              page: options.page,
              'per-page': options.itemsPerPage,
            }
          : {}

        api
          .get(API_USER_GROUPS, { params: params })
          .then((resp) => {
            commit(SET_GROUPS, resp.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUsers: ({ commit }, options) => {
      return new Promise((resolve, reject) => {
        let params = options
          ? {
              page: options.page,
              'per-page': options.itemsPerPage,
            }
          : {}

        api
          .get(API_USERS, { params: params })
          .then((resp) => {
            commit(SET_USERS, resp.data)
            commit(SET_USERS_TOTAL, resp.headers['x-pagination-total-count'])
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addUser: (store, user) => {
      return new Promise((resolve, reject) => {
        api
          .post(API_USERS, { ...user, ...user.profile })
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateUser: (store, user) => {
      return new Promise((resolve, reject) => {
        api
          .put(API_USERS + '/' + user.id, { ...user, ...user.profile })
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUser: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        api
          .get(API_USERS + '/' + id)
          .then((resp) => {
            commit(SET_USER, resp.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    delUser: (store, id) => {
      return new Promise((resolve, reject) => {
        api
          .delete(API_USERS + '/' + id)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
