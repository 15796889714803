import { api, opencart } from '../helpers/http'
import { API_CALENDAR, API_EVENT_ORDERS } from '@/api'
import {
  SET_CALENDAR_ITEMS,
  SET_CALENDAR_ITEMS_TOTAL,
  SET_CALENDAR_OPTIONS,
  SET_CALENDAR_ITEM,
  SET_CALENDAR_PARAMS_BLOCK_ID,
  SET_CALENDAR_PARAMS_FIELDS,
  SET_CALENDAR_PARAMS_PERIOD,
  SET_EVENT_ORDERS,
  SET_LOADING,
} from '../mutation-types'

export default {
  namespaced: true,
  state: {
    loading: true,
    calendarItem: {},
    calendarItems: [],
    total: -1,
    params: {
      page: 1,
      'per-page': 10,
      eventId: null,
      period: 'future',
      customFields: null,
      dateSort: null,
      sortField: null,
      sortDesc: false,
    },
    eventOrders: [],
  },
  mutations: {
    [SET_LOADING]: (state, payload) => {
      state.loading = payload
    },
    [SET_CALENDAR_PARAMS_BLOCK_ID]: (state, id) => {
      state.params.eventId = id
    },
    [SET_CALENDAR_PARAMS_PERIOD]: (state, period) => {
      state.params.period = period
    },
    [SET_CALENDAR_PARAMS_FIELDS]: (state, fields) => {
      state.params.customFields = fields
    },
    [SET_CALENDAR_ITEMS]: (state, data) => {
      state.calendarItems = data
    },
    [SET_CALENDAR_ITEMS_TOTAL]: (state, total) => {
      state.total = parseInt(total)
    },
    [SET_CALENDAR_OPTIONS]: ({ params }, options) => {
      params.page = options.page
      params['per-page'] = options.itemsPerPage
      params.sortField = options.sortBy
      params.sortDesc = options.sortDesc.map((el) => (el ? 1 : 0))
    },
    [SET_CALENDAR_ITEM]: (state, data) => {
      state.calendarItem = data
    },
    [SET_EVENT_ORDERS]: (state, orders) => {
      state.eventOrders = orders
    },
  },
  getters: {
    loading: ({ loading }) => loading,
    total: (state) => state.total,
    calendar: (state) => state.calendarItems,
    eventOrders: ({ eventOrders }) => {
      let result = []
      eventOrders.forEach((el) => {
        el.products.forEach((p) => {
          result.push({
            number: el.order_id,
            battletag: el.custom_field[1],
            id: p.external_id,
          })
        })
      })
      return result
    },
  },
  actions: {
    getEventOrders: ({ commit }, eventId) => {
      return new Promise((resolve, reject) => {
        opencart
          .get(`${API_EVENT_ORDERS}/${eventId}`)
          .then((resp) => {
            commit(SET_EVENT_ORDERS, resp.data.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setOptions: ({ commit }, options) => {
      commit(SET_CALENDAR_OPTIONS, options)
    },
    resetOptions: ({ commit }) => {
      commit(SET_CALENDAR_PARAMS_BLOCK_ID, null)
      commit(SET_CALENDAR_PARAMS_FIELDS, null)
      commit(SET_CALENDAR_PARAMS_PERIOD, null)
    },
    setFields: ({ commit }, fields) => {
      commit(SET_CALENDAR_PARAMS_FIELDS, fields)
    },
    setEventId: ({ commit }, id) => {
      commit(SET_CALENDAR_PARAMS_BLOCK_ID, id)
    },
    setPeriod: ({ commit }, period) => {
      commit(SET_CALENDAR_PARAMS_PERIOD, period)
    },
    getItems: ({ commit, state }) => {
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        api
          .get(API_CALENDAR, { params: state.params })
          .then((resp) => {
            commit(SET_CALENDAR_ITEMS, resp.data)
            commit(
              SET_CALENDAR_ITEMS_TOTAL,
              resp.headers['x-pagination-total-count']
            )
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            commit(SET_LOADING, false)
          })
      })
    },
    addItem: (store, item) => {
      return new Promise((resolve, reject) => {
        api
          .post(API_CALENDAR, item)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateItem: (store, item) => {
      return new Promise((resolve, reject) => {
        api
          .put(API_CALENDAR + '/' + item.id, item)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getItem: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        api
          .get(API_CALENDAR + '/' + id)
          .then((resp) => {
            commit(SET_CALENDAR_ITEM, resp.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    delItem: (store, id) => {
      return new Promise((resolve, reject) => {
        api
          .delete(API_CALENDAR + '/' + id)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
