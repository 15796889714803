<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="dialog = false">
    <v-card>
      <v-card-title class="teal darken-4 white--text">
        <span>HMB CCC</span>
        <v-spacer />
        <v-btn icon depressed color="white" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="data">
        <v-switch
          v-model="item.active"
          :label="item.active ? 'Активен' : 'Не активен'"
          @click="$emit('toggle-activity', item)"
        ></v-switch>
        <v-row>
          <v-col>JobId</v-col>
          <v-col
            >{{ item.jobId }}
            <v-btn icon color="default" @click="copy(item.jobId)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>CCC</v-col>
          <v-col
            >{{ item.ccc }}
            <v-btn icon color="default" @click="copy(item.ccc)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>Booster License Id</v-col>
          <v-col
            >{{ item.boosterLicenseId }}
            <v-btn icon color="default" @click="copy(item.boosterLicenseId)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { SNACK_SUCCESS } from '@/store/mutation-types'
export default {
  name: 'HmbDialog',
  props: ['item', 'value'],
  data() {
    return {
      dialog: false,
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    },
  },
  methods: {
    copy(text) {
      navigator.clipboard.writeText(text)
      this.$store.commit(SNACK_SUCCESS, `Скопировано "${text}"`)
    },
  },
}
</script>

<style scoped>
.data {
  font-size: 150%;
  margin-top: 25px;
}
</style>
