<template>
  <div class="events-bar">
    <v-app-bar-title>Календарь</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn title="Сбросить фильтр" icon depressed dense @click="resetFilter()"
      ><v-icon>mdi-refresh</v-icon></v-btn
    >
    <v-spacer></v-spacer>
    <v-btn-toggle v-model="regionSelected" dense>
      <v-btn color="primary" @click="filterRegion(!regionSelected ? 'US' : '')"
        >US</v-btn
      >
      <v-btn color="primary" @click="filterRegion(!regionSelected ? 'EU' : '')"
        >EU</v-btn
      >
    </v-btn-toggle>

    <v-spacer></v-spacer>
    <v-btn-toggle v-model="timeSelected" dense>
      <v-btn color="primary" @click="filterPeriod(!timeSelected ? 'past' : '')"
        >Прошедшие</v-btn
      >
      <v-btn
        color="primary"
        @click="filterPeriod(!timeSelected ? 'future' : '')"
        >Предстоящие</v-btn
      >
    </v-btn-toggle>
    <v-spacer></v-spacer>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('calendar')

export default {
  data() {
    return {
      timeSelected: 1,
      regionSelected: false,
    }
  },
  methods: {
    ...mapActions(['setFields', 'setBlockId', 'setPeriod', 'getItems']),
    filterRegion(region) {
      this.setFields(
        region
          ? {
              Region: region,
            }
          : {}
      )
      this.getItems()
    },
    filterPeriod(period) {
      this.setPeriod(period)
      this.getItems()
    },
    resetFilter() {
      this.regionSelected = null
      this.timeSelected = null
      this.setPeriod(null)
      this.setFields(null)
      this.getItems()
    },
  },
}
</script>

<style>
.events-bar {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
</style>
