export const SET_COUPONS_LIST = 'SET_COUPONS_LIST'
export const SET_COUPON_NAME = 'SET_COUPON_NAME'
export const SET_COUPON_CODE = 'SET_COUPON_CODE'
export const SET_COUPON_DISCOUNT = 'SET_COUPON_DISCOUNT'
export const SET_COUPON_START = 'SET_COUPON_START'
export const SET_COUPON_END = 'SET_COUPON_END'
export const SET_COUPON_STATUS = 'SET_COUPON_STATUS'
export const SET_COUPON_ID = 'SET_COUPON_ID'
export const SET_COUPONS_TOTAL = 'SET_COUPONS_TOTAL'
export const SET_COUPONS_OPTIONS = 'SET_COUPONS_OPTIONS'
export const SET_COUPON_TYPE = 'SET_COUPON_TYPE'
export const SET_COUPON_TOTAL = 'SET_COUPON_TOTAL'
export const SET_COUPON_USES_TOTAL = 'SET_COUPON_USES_TOTAL'
export const SET_COUPON_USES_CUSTOMER = 'SET_COUPON_USES_CUSTOMER'

export const SET_TOKEN = 'SET_TOKEN'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'
export const SET_OC_TOKEN = 'SET_OC_TOKEN'

export const SNACK_ERROR = 'snack_error'
export const SNACK_SUCCESS = 'snack_success'
export const SNACK_HIDE = 'snack_hide'

export const SET_USERS = 'set_users'
export const SET_USERS_TOTAL = 'set_users_total'
export const SET_USERS_OPTIONS = 'set_users_options'
export const SET_USER = 'set_user'

export const SET_GROUPS = 'set_groups'
export const SET_GROUPS_TOTAL = 'set_groups_total'

export const SET_BLACKLIST = 'set_blacklist'
export const SET_BLACKLIST_TOTAL = 'set_blacklist_total'
export const SET_BLACKLIST_ITEM = 'set_blacklist_item'

export const SET_EVENTBLOCKS = 'set_EVENTBLOCKS'
export const SET_EVENTBLOCKS_TOTAL = 'set_EVENTBLOCKS_total'
export const SET_EVENTBLOCKS_ITEM = 'set_EVENTBLOCKS_item'

export const SET_EVENTS = 'set_EVENTS'
export const SET_EVENTS_TOTAL = 'set_EVENTS_total'
export const SET_EVENTS_ITEM = 'set_EVENTS_item'

export const SET_EVENTFIELDS = 'set_event_fields'
export const SET_EVENTFIELDS_TOTAL = 'set_event_fields_total'
export const SET_EVENTFIELDS_EVENT = 'set_event_fields_event'

export const SET_COMPLAINTS_LIST = 'SET_COMPLAINTS_LIST'
export const SET_COMPLAINTS_ID = 'SET_COMPLAINTS_ID'
export const SET_COMPLAINTS_DATE = 'SET_COMPLAINTS_DATE'
export const SET_COMPLAINTS_ORDER = 'SET_COMPLAINTS_ORDER'
export const SET_COMPLAINTS_COMMENT = 'SET_COMPLAINTS_COMMENT'
export const SET_COMPLAINTS_RESOLVED = 'SET_COMPLAINTS_RESOLVED'
export const SET_COMPLAINTS_CONTACTS = 'SET_COMPLAINTS_CONTACTS'
export const SET_COMPLAINT = 'SET_COMPLAINT'

export const SET_DISPUTES_LIST = 'SET_DISPUTES_LIST'
export const SET_DISPUTES_ID = 'SET_DISPUTES_ID'
export const SET_DISPUTES_DATE = 'SET_DISPUTES_DATE'
export const SET_DISPUTES_ORDER = 'SET_DISPUTES_ORDER'
export const SET_DISPUTES_COMMENT = 'SET_DISPUTES_COMMENT'
export const SET_DISPUTES_RESOLVED = 'SET_DISPUTES_RESOLVED'
export const SET_DISPUTES_CONTACTS = 'SET_DISPUTES_CONTACTS'
export const SET_DISPUTE = 'SET_DISPUTE'

export const SET_SEARCH_STRING = 'SEARCH_STRING'
export const SET_ORDERS = 'SET_ORDERS'
export const SET_ORDER = 'SET_ORDER'
export const SET_STORE_INFO = 'SET_STORE_INFO'
export const SET_FILTER_STATUS = 'SET_ORDER_FILTER_STATUS'
export const SET_FILTER_DATE_FROM = 'SET_orders_filter_date_from'
export const SET_FILTER_DATE_TO = 'SET_orders_filter_date_to'
export const SET_FILTER_OPTIONS = 'set_orders_options'
export const SET_FILTER_OPTIONS_PAGE = 'SET_FILTER_OPTIONS_PAGE'
export const SET_TOTAL_ORDERS = 'set_total_orders'
export const UPDATE_ORDER_STATUS = 'upd_ord_st'
export const SET_LOADING = 'set_loading'

export const SET_CALENDAR_ITEM = 'set_calendar_item'
export const SET_CALENDAR_ITEMS = 'set_calendar_items'
export const SET_CALENDAR_ITEMS_TOTAL = 'set_calendar_items_total'
export const SET_CALENDAR_OPTIONS = 'set_calendar_options'
export const SET_CALENDAR_PARAMS_FIELDS = 'set_calendar_fields'
export const SET_CALENDAR_PARAMS_PERIOD = 'set_calendar_period'
export const SET_CALENDAR_PARAMS_BLOCK_ID = 'set_calendar_block_id'

export const SET_BLACKLIST_USERS = 'set_users_in_blacklist'
export const SET_NEW_ORDERS = 'set_new_orders'
export const SET_CUSTOM_FIELDS = 'set_custom_fields'
export const SET_EVENT_ORDERS = 'set_event_orders'

export const SET_ORDER_ITEM = 'set_order_item'

export const SET_REFUNDS = 'set_refunds'
export const SET_REFUNDS_TOTAL = 'set_refunds_total'

export const SET_PAYOUTS_LIST = 'set_payouts_list'
export const SET_PAYOUTS_TOTAL = 'set_payouts_list_total'
export const SET_PAYOUTS_SUM = 'set_payouts_sum'

export const SET_BOOSTER = 'set_booster'
export const SET_BOOSTER_LIST = 'set_booster_list'
export const SET_BOOSTER_TOTAL = 'set_booster_list_total'
export const ADD_ONE_BOOSTER = 'add_one_booster'

export const SET_HMB_CCC = 'set_hmb_ccc'
export const SET_HMB_CCC_LIST = 'set_hmb_ccc_list'
export const SET_HMB_TOTAL = 'set_hmb_total'
export const SET_ONLINE_CUSTOMERS = 'set_online_customers'
export const SET_ONLINE_BOOSTERS = 'set_onilne_boosters'

export const SET_TG_TOTAL = 'set_tg_total'
export const SET_TG = 'set_tg'
