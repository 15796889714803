import moment from 'moment'

export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DATETIME_DB_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export function toDB(date, format) {
  let tz = moment().utcOffset()

  return moment(date, format).subtract(tz, 'minutes').format(DATETIME_DB_FORMAT)
}

export function fromDB(date, format) {
  let tz = moment().utcOffset()

  return moment(date, DATETIME_DB_FORMAT).add(tz, 'minutes').format(format)
}

export function fromDBtoCET(date, format) {
  return moment(date, DATETIME_DB_FORMAT).add(120, 'minutes').format(format)
}

export function fromDBtoEST(date, format) {
  return moment(date, DATETIME_DB_FORMAT).add(-300, 'minutes').format(format)
}
