<template>
  <v-form @submit.prevent="auth">
    <v-card>
      <v-toolbar flat color="primary">
        <v-toolbar-title class="white--text">Вход</v-toolbar-title></v-toolbar
      >
      <v-card-text>
        <v-text-field
          v-model="login"
          height="32"
          prepend-icon="mdi-account"
          label="Почта"
          type="email"
        ></v-text-field>
        <v-text-field
          v-model="password"
          height="32"
          prepend-icon="mdi-lock"
          label="Пароль"
          type="password"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-row justify="space-between">
          <v-col cols="auto"
            ><v-btn color="primary" type="submit">Войти</v-btn>
          </v-col>
          <v-col cols="auto"><v-btn>Сбросить пароль</v-btn></v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      login: '',
      password: '',
    }
  },
  methods: {
    auth() {
      this.$store
        .dispatch('auth', {
          login: this.login,
          password: this.password,
        })
        .then(() => {
          this.$router.push('/')
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
