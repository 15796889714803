import { api } from '@/store/helpers/http'

import {
  SET_DISPUTES_LIST,
  SET_DISPUTES_ID,
  SET_DISPUTES_DATE,
  SET_DISPUTES_ORDER,
  SET_DISPUTES_COMMENT,
  SET_DISPUTES_RESOLVED,
  SET_DISPUTES_CONTACTS,
  SET_DISPUTE,
} from '@/store/mutation-types'

export default {
  namespaced: true,
  state: {
    headers: [
      { text: 'Id:', value: 'id' },
      { text: 'Источник', value: 'type' },
      {
        text: 'Дата:',
        value: 'date',
      },
      { text: 'Сумма', value: 'amount' },
      //{ text: 'Ответить до:', value: 'duedate'},
      //{ text: '№ заказа:', value: 'order' },
      { text: 'Жалоба:', value: 'comment' },
      { text: 'Статус:', value: 'resolved' },
      //{ text: 'Контакты:', value: 'contacts' },
      { text: 'Действия:', value: 'action' },
    ],
    disputes: [],
    dispute: {},
  },
  getters: {
    disputesList: (state) =>
      state.disputes.map((item) => {
        item.resolved = !!item.resolved
        return item
      }),
  },
  mutations: {
    [SET_DISPUTE](state, payload) {
      state.dispute = payload
    },
    [SET_DISPUTES_LIST](state, payload) {
      state.disputes = payload
    },
    [SET_DISPUTES_ID](state, payload) {
      state.id = payload
    },
    [SET_DISPUTES_DATE](state, payload) {
      state.date = payload
    },
    [SET_DISPUTES_ORDER](state, payload) {
      state.order = payload
    },
    [SET_DISPUTES_COMMENT](state, payload) {
      state.comment = payload
    },
    [SET_DISPUTES_RESOLVED](state, payload) {
      state.resolved = payload
    },
    [SET_DISPUTES_CONTACTS](state, payload) {
      state.contacts = payload
    },
  },
  actions: {
    async getDisputesList({ commit }) {
      try {
        const response = await api.get('/dispute')

        const { data: disputes } = response

        commit(SET_DISPUTES_LIST, disputes)
      } catch (error) {
        console.error(error)
      }
    },
    async getDispute({ commit }, { complainId, type }) {
      try {
        const response = await api.get(`/dispute/${type}/${complainId}`)
        const { data: dispute } = response

        commit(SET_DISPUTE, dispute)
      } catch (error) {
        console.error(error)
      }
    },
    resetDispute({ commit }) {
      commit(SET_DISPUTE, {})
    },
    setDisputeData({ commit }, dispute) {
      const { id, date, order, comment, resolved, contacts } = dispute

      commit(SET_DISPUTES_ID, id)
      commit(SET_DISPUTES_DATE, date)
      commit(SET_DISPUTES_ORDER, order)
      commit(SET_DISPUTES_COMMENT, comment)
      commit(SET_DISPUTES_CONTACTS, contacts)
      commit(SET_DISPUTES_RESOLVED, resolved)
    },
    async changeDispute({ commit, dispatch, state }, payload) {
      try {
        const { dispute, resolvedState } = payload

        dispatch('setDisputeData', dispute)
        commit(SET_DISPUTES_RESOLVED, resolvedState)

        const { id, date, order, comment, resolved, contacts } = state
        const data = {
          id,
          date,
          order,
          comment,
          resolved,
          contacts,
        }

        await api.put(`/dispute/${id}`, data)
        await dispatch('getDisputesList')
      } catch (error) {
        console.error(error)
      }
    },
  },
}
