var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.disputesList},on:{"update:options":_vm.getDisputesList},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("dateFormat")(item.date)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.amount)+" "+_vm._s(item.currency))])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[_vm._v(_vm._s(item.comment))])]}},{key:"item.resolved",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.resolved ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.resolved ? 'Решён' : 'Не решён')+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"default"},on:{"click":function($event){return _vm.viewDispute(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])}),_c('dispute-dialog',{attrs:{"item":_vm.dispute},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }