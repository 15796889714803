<template>
  <div>
    <crud-page
      :options.sync="options"
      :total="total"
      :headers="headers"
      :items="usersToDisplay"
      title="Users"
      @create="createItem"
      @edit="editItem"
      @delete="deleteItem"
    />

    <v-dialog v-model="dialog" width="800">
      <v-card>
        <form @submit.prevent="save">
          <v-card-title class="teal darken-4 white--text">
            {{
              user.id ? 'Редактирование пользователя' : 'Создание пользователя'
            }}

            <v-btn
              class="ml-auto"
              icon
              depressed
              color="white"
              @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>

          <v-card-text class="mt-5">
            <v-row>
              <v-col>
                <div>
                  <v-select v-model="user.group" :items="groupsSelect" />
                </div>
                <div>
                  <booster-select
                    v-if="user.group === boosterGroupId"
                    :key="user.id"
                    v-model="user.booster"
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    type="email"
                  />
                </div>
                <div>
                  <v-text-field
                    v-model="user.password"
                    label="Password"
                    type="password"
                  />
                </div>
                <div>
                  <v-checkbox v-model="user.active" label="Active" />
                </div>
                <div v-if="user.id">
                  <event-selector
                    :key="user.id"
                    v-model="user.events"
                    multiple
                  />
                </div>
              </v-col>
              <v-col>
                <h5>Профиль</h5>
                <div v-for="(item, key) in user.profile" :key="key">
                  <v-text-field v-model="user.profile[key]" :label="key" />
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" type="submit">Сохранить</v-btn>
            <v-btn @click="dialog = !dialog">Закрыть</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CrudPage from '../components/CrudPage.vue'
import { SNACK_SUCCESS } from '../store/mutation-types'
import BoosterSelect from '../components/BoosterSelect.vue'
import EventSelector from '../components/wizard/EventSelector.vue'

const { mapGetters } = createNamespacedHelpers('users')

const user = {
  email: '',
  active: true,
  group: 1,
  booster: 0,
  events: [],
  profile: {
    phone: '',
    skype: '',
    discord: '',
    telegram: '',
    birthdate: '',
  },
}

export default {
  name: 'Users',
  components: { CrudPage, BoosterSelect, EventSelector },
  data() {
    return {
      dialog: false,
      options: null,
      user: { ...user },
      headers: [
        { text: 'id', value: 'id' },
        { text: 'email', value: 'email' },
        { text: 'phone', value: 'phone' },
        { text: 'birthdate', value: 'birthdate' },
        { text: 'skype', value: 'skype' },
        { text: 'telegram', value: 'telegram' },
        { text: 'discord', value: 'discord' },
        { text: 'active', value: 'active' },
        { text: 'group', value: 'group' },
        { text: 'actions', value: 'actions' },
      ],
    }
  },
  computed: {
    ...mapGetters(['usersToDisplay', 'groups', 'total', 'groupsSelect']),
    boosterGroupId() {
      let boosterGroup = this.groupsSelect.find((el) => el.text === 'Бустер')
      return boosterGroup['value']
    },
  },
  watch: {
    options: function () {
      this.$store.dispatch('users/getUsers', this.options)
    },
  },
  mounted() {
    this.$store.dispatch('users/getGroups')
  },
  methods: {
    createItem() {
      this.user = { ...user }
      this.dialog = true
    },
    editItem(id) {
      this.$store.dispatch('users/getUser', id).then((resp) => {
        this.user = resp.data
      })
      this.dialog = true
    },
    deleteItem(id) {
      if (confirm('Вы уверены?')) {
        this.$store.dispatch('users/delUser', id).then(() => {
          this.$store.dispatch('users/getUsers', this.options)
          this.$store.commit(SNACK_SUCCESS, 'Пользователь удалён')
        })
      }
    },
    save() {
      let action = this.user.id ? 'users/updateUser' : 'users/addUser'

      this.$store.dispatch(action, this.user).then(() => {
        this.$store.dispatch('users/getUsers', this.options)
        this.$store.commit(SNACK_SUCCESS, 'Пользователь сохранён')
        this.dialog = false
        this.user = { ...user }
      })
    },
  },
}
</script>

<style></style>
