<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="complaintsList"
      @update:options="getComplaintsList"
    >
      <template v-slot:item.date="{ item }">
        <span class="text-no-wrap">{{ item.date | dateFormat }}</span>
      </template>
      <template v-slot:item.comment="{ item }">
        <div class="pa-4">{{ item.comment }}</div>
      </template>
      <template v-slot:item.resolved="{ item }">
        <v-chip :color="item.resolved ? 'green' : 'red'" text-color="white">
          {{ item.resolved ? 'Решён' : 'Не решён' }}
        </v-chip>
      </template>
      <template v-slot:item.contacts="{ item }">
        <div class="d-flex">
          <v-btn icon :href="`mailto:${item.contacts.email}`"
            ><v-icon>mdi-email</v-icon></v-btn
          ><v-btn
            icon
            color="primary"
            :href="`skype:${item.contacts.skype}?call`"
            class="ml-3"
            ><v-icon>mdi-skype</v-icon></v-btn
          >
        </div>
      </template>
      <template v-slot:item.contacts="{ item }">
        <div class="d-flex">
          <v-btn icon :href="`mailto:${item.contacts.email}`"
            ><v-icon>mdi-email</v-icon></v-btn
          ><v-btn
            icon
            color="primary"
            :href="`skype:${item.contacts.skype}?call`"
            class="ml-3"
            ><v-icon>mdi-skype</v-icon></v-btn
          >
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-simple-checkbox
          v-model="item.resolved"
          v-ripple
          @input="checked(item, $event)"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers(
  'complaints'
)
import { fromDB, DATE_FORMAT } from '@/helpers/dates'

export default {
  name: 'Complaints',
  components: {},
  filters: {
    dateFormat(date) {
      return fromDB(date, DATE_FORMAT)
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['headers', 'complaints']),
    ...mapGetters(['complaintsList']),
  },
  methods: {
    ...mapActions(['getComplaintsList', 'changeComplaint']),
    checked(complaint, resolvedState) {
      this.changeComplaint({ complaint, resolvedState })
    },
  },
}
</script>

<style lang="scss" scoped>
.complaints {
  padding: 16px;
}
</style>
