<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="disputesList"
      @update:options="getDisputesList"
    >
      <template v-slot:item.date="{ item }">
        <span class="text-no-wrap">{{ item.date | dateFormat }}</span>
      </template>
      <template v-slot:item.amount="{ item }">
        <span class="text-no-wrap">{{ item.amount }} {{ item.currency }}</span>
      </template>
      <template v-slot:item.comment="{ item }">
        <div class="pa-4">{{ item.comment }}</div>
      </template>
      <template v-slot:item.resolved="{ item }">
        <v-chip :color="item.resolved ? 'green' : 'red'" text-color="white">
          {{ item.resolved ? 'Решён' : 'Не решён' }}
        </v-chip>
      </template>
      <!-- <template v-slot:item.contacts="{ item }">
        <div class="d-flex">
          <v-btn icon :href="`mailto:${item.contacts.email}`"
            ><v-icon>mdi-email</v-icon></v-btn
          ><v-btn
            icon
            color="primary"
            :href="`skype:${item.contacts.skype}?call`"
            class="ml-3"
            ><v-icon>mdi-skype</v-icon></v-btn
          >
        </div>
      </template> -->
      <!-- <template v-slot:item.contacts="{ item }">
        <div class="d-flex">
          <v-btn icon :href="`mailto:${item.contacts.email}`"
            ><v-icon>mdi-email</v-icon></v-btn
          ><v-btn
            icon
            color="primary"
            :href="`skype:${item.contacts.skype}?call`"
            class="ml-3"
            ><v-icon>mdi-skype</v-icon></v-btn
          >
        </div>
      </template> -->
      <template v-slot:item.action="{ item }">
        <!-- <v-simple-checkbox
          v-model="item.resolved"
          v-ripple
          @input="checked(item, $event)"
        ></v-simple-checkbox> -->
        <v-btn small icon color="default" @click="viewDispute(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <dispute-dialog v-model="dialog" :item="dispute" />
  </v-container>
</template>

<script>
import DisputeDialog from '@/components/disputes/DisputeDialog'
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('disputes')
import { fromDB, DATE_FORMAT } from '@/helpers/dates'

export default {
  name: 'Disputes',
  components: {
    DisputeDialog,
  },
  filters: {
    dateFormat(date) {
      return fromDB(date, DATE_FORMAT)
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState(['headers', 'disputes', 'dispute']),
    ...mapGetters(['disputesList']),
  },
  methods: {
    ...mapActions(['getDisputesList', 'getDispute', 'resetDispute']),
    viewDispute(item) {
      this.resetDispute()
      this.getDispute({ complainId: item.id, type: item.type })
      this.dialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.disputes {
  padding: 16px;
}
</style>
