<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="boosters"
      :options.sync="options"
      :server-items-length="total"
    >
      <template v-slot:item.licenseId="{ item }">
        <div class="copy-to-clipboard" @click="copyToClipboard(item.licenseId)">
          {{ item.licenseId }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="showDialog(item)"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-btn icon @click="delBooster(item)"
          ><v-icon>mdi-delete</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <BoosterDialog
      :key="booster.licenseId"
      v-model="dialog"
      :item="booster"
      @submit="saveBooster"
      @refreshToken="refresh"
    />

    <v-btn color="red" dark bottom right fab fixed @click="showDialog()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import BoosterDialog from '../components/boosters/BoosterDialog.vue'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('booster')
import { SNACK_SUCCESS, SNACK_ERROR } from '@/store/mutation-types'

export default {
  components: {
    BoosterDialog,
  },
  data() {
    return {
      dialog: false,
      booster: {},
      options: {},
      headers: [
        { text: 'id', value: 'id' },
        { text: 'name', value: 'name' },
        { text: 'license', value: 'licenseId' },
        { text: 'info', value: 'info' },
        //{text: 'userId', value: 'userId'},
        { text: 'skype', value: 'skype' },
        { text: 'discord', value: 'discord' },
        { text: 'Приоритет', value: 'priority' },
        { text: 'actions', value: 'actions' },
      ],
    }
  },
  computed: {
    ...mapGetters(['boosters', 'total']),
  },
  methods: {
    ...mapActions([
      'getItem',
      'getItems',
      'addItem',
      'delItem',
      'updateItem',
      'refreshToken',
    ]),
    delBooster(item) {
      if (confirm('Вы уверены?')) {
        this.delItem(item)
          .then(() => {
            this.getItems(this.options)
            this.$store.commit(SNACK_SUCCESS, 'Запись удалёна')
          })
          .catch((err) => {
            this.$store.commit(SNACK_ERROR, err)
          })
      }
    },
    showDialog(item) {
      this.booster = item ? this.boosters.find(({ id }) => id === item.id) : {}
      this.dialog = true
    },
    saveBooster(item) {
      this[item.id ? 'updateItem' : 'addItem'](item)
        .then(() => {
          this.getItems(this.options)
          this.$store.commit(SNACK_SUCCESS, 'Бустер сохранён')
          this.dialog = false
        })
        .catch((err) => {
          this.$store.commit(SNACK_ERROR, err)
        })
    },
    refresh(item) {
      this.refreshToken(item).then((resp) => {
        this.booster = resp.data
        this.$store.commit(SNACK_SUCCESS, 'Токен обновлён')
      })
    },
    copyToClipboard(text) {
      this.$clipboard(text)
      this.$store.commit(SNACK_SUCCESS, `Скопировано ${text}`)
    },
  },
  watch: {
    options() {
      this.getItems(this.options)
    },
  },
}
</script>
