<template>
  <div>
    <h4 class="mr-5">Добавить поле</h4>
    <form
      class="d-flex align-center justify-space-between mb-5"
      @submit.prevent="save"
    >
      <v-text-field
        v-model="item.name"
        hide-details
        label="Название"
        class="mr-5"
      />
      <v-text-field
        v-model="item.value"
        hide-details
        label="Значение"
        class="mr-5"
      />
      <v-btn type="submit" color="primary" class="flex-shrink">Добавить</v-btn>
    </form>
    <h4>Поля эвента</h4>
    <v-data-table
      :headers="headers"
      :items="eventfields"
      :options.sync="options"
      :server-items-length="total"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="delItem(item.id)"
          ><v-icon>mdi-trash-can-outline</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('eventfields')
import { SNACK_SUCCESS } from '@/store/mutation-types'

const emptyItem = {
  name: '',
  value: '',
}

export default {
  props: ['eventId'],
  data() {
    return {
      item: { ...emptyItem },
      options: null,
      headers: [
        { text: 'id', value: 'id' },
        { text: 'Название', value: 'name' },
        { text: 'Значение', value: 'value' },
        { text: 'Действия', value: 'actions' },
      ],
    }
  },
  watch: {
    options: function () {
      this.getEventFields(this.options)
    },
    eventId: function () {
      this.setEvent(this.eventId)
      this.getEventFields(this.options)
    },
  },
  mounted() {
    this.setEvent(this.eventId)
  },
  computed: {
    ...mapGetters(['eventfields', 'total']),
  },
  methods: {
    ...mapActions([
      'setEvent',
      'getEventFields',
      'addEventField',
      'delEventField',
    ]),
    save() {
      this.addEventField(this.item).then(() => {
        this.getEventFields(this.options)
        this.$store.commit(SNACK_SUCCESS, 'Поле добавлено')
        this.item = { ...emptyItem }
      })
    },
    delItem(id) {
      this.delEventField(id).then(() => {
        this.$store.commit(SNACK_SUCCESS, 'Поле удалено')
        this.getEventFields(this.options)
      })
    },
  },
}
</script>

<style></style>
