import Vue from 'vue'
import VueRouter from 'vue-router'
import Panel from '@/views/Panel.vue'
import Authorization from '@/views/Authorization.vue'
import Orders from '@/views/Orders.vue'
import Blacklist from '@/views/Blacklist.vue'
import Calendar from '@/views/Calendar.vue'
import Events from '@/views/events/Events'
import Users from '@/views/Users'
import Complaints from '@/views/Complaints'
import Disputes from '@/views/Disputes'
import Coupons from '@/views/Coupons'
import Wizard from '@/views/Wizard'
import Refunds from '@/views/Refunds'
import Payouts from '@/views/Payouts'
import Boosters from '@/views/Boosters'
import Ccc from '@/views/CCC'
import KeyOnline from '@/views/KeyOnline'
import Tg from '@/views/Tg'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Panel,
    meta: {
      disableSidemenu: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Authorization,
    meta: {
      disableNavigation: true,
    },
  },
  {
    path: '/orders',
    component: Orders,
    meta: {
      toolbar: 'OrdersBar',
    },
  },
  {
    path: '/blacklist',
    name: 'Чёрный список',
    component: Blacklist,
  },
  {
    path: '/calendar',
    name: 'Календарь',
    component: Calendar,
    meta: {
      toolbar: 'EventsBar',
    },
  },
  {
    path: '/events',
    name: 'Эвенты',
    component: Events,
  },
  {
    path: '/users',
    name: 'Пользователи',
    component: Users,
  },
  {
    path: '/complaints',
    name: 'Complaints',
    component: Complaints,
  },
  {
    path: '/disputes',
    name: 'Disputes',
    component: Disputes,
  },
  {
    path: '/coupons',
    name: 'Купоны',
    component: Coupons,
  },
  {
    path: '/wizard',
    name: 'Мастер эвентов',
    component: Wizard,
  },
  {
    path: '/refunds',
    name: 'Рефанды',
    component: Refunds,
  },
  {
    path: '/payouts',
    name: 'Выплаты',
    component: Payouts,
  },
  {
    path: '/boosters',
    name: 'Boosters',
    component: Boosters,
  },
  {
    path: '/ccc',
    name: 'CCC',
    component: Ccc,
  },
  {
    path: '/keys-online',
    name: 'Keys Online',
    component: KeyOnline,
  },
  {
    path: '/tg',
    name: 'Tg users',
    component: Tg,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('x-token')

  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
