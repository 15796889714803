export const menus = [
  {
    name: 'Магазин',
    groups: ['admin', 'operator'],
    items: [
      {
        name: 'Заказы',
        link: '/orders',
        icon: 'mdi-shopping',
        groups: ['admin', 'operator'],
      },
      {
        name: 'Complaints',
        link: '/complaints',
        icon: 'mdi-halloween',
        groups: ['admin', 'operator'],
      },
      {
        name: 'Disputes',
        link: '/disputes',
        icon: 'mdi-cash-remove',
        groups: ['admin', 'operator'],
      },
      {
        name: 'Чёрный список',
        link: '/blacklist',
        icon: 'mdi-skull-crossbones',
        groups: ['admin', 'operator'],
      },
      {
        name: 'Купоны',
        link: '/coupons',
        icon: 'mdi-tag-outline',
        groups: ['admin', 'operator'],
      },
    ],
  },
  {
    name: 'Hide My Boost',
    groups: ['admin', 'content-manager', 'operator'],
    items: [
      {
        name: 'CCC',
        link: '/ccc',
        icon: 'mdi-key',
        groups: ['admin', 'content-manager', 'operator'],
      },
      {
        name: 'Keys online',
        link: '/keys-online',
        icon: 'mdi-key',
        groups: ['admin', 'content-manager', 'operator'],
      },
    ],
  },
  {
    name: 'Расписание',
    groups: ['admin', 'content-manager', 'operator', 'booster'],
    items: [
      {
        name: 'Календарь',
        link: '/calendar',
        icon: 'mdi-calendar-month',
        groups: ['admin', 'operator', 'content-manager', 'booster'],
      },
      {
        name: 'Эвенты',
        link: '/events',
        icon: 'mdi-calendar',
        groups: ['admin', 'content-manager'],
      },
      {
        name: 'Мастер эвентов',
        link: '/wizard',
        icon: 'mdi-wizard-hat',
        groups: ['admin', 'content-manager'],
      },
    ],
  },
  {
    name: 'Админ',
    groups: ['admin'],
    items: [
      {
        name: 'Пользователи',
        link: '/users',
        icon: 'mdi-account',
        groups: ['admin'],
      },
      {
        name: 'Boosters',
        link: '/boosters',
        icon: 'mdi-account',
        groups: ['admin'],
      },
      {
        name: 'Рефанды',
        link: '/refunds',
        icon: 'mdi-cash-refund',
        groups: ['admin'],
      },
      {
        name: 'Выплаты',
        link: '/payouts',
        icon: 'mdi-cash-refund',
        groups: ['admin'],
      },
      {
        name: 'Пользователи Tg',
        link: '/tg',
        icon: 'mdi-account',
        groups: ['admin'],
      },
    ],
  },
]
