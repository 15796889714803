<template>
  <div>
    <crud-page
      :items="blacklist"
      :total="total"
      :headers="headers"
      :options.sync="options"
      title="Blacklist"
      @create="createItem"
      @edit="editItem"
      @delete="deleteItem"
    />

    <v-dialog v-model="dialog" width="800">
      <v-card>
        <form @submit.prevent="save">
          <v-card-title class="teal darken-4 white--text">
            {{ item.id ? 'Редактирование записи' : 'Создание записи' }}

            <v-btn
              class="ml-auto"
              icon
              depressed
              color="white"
              @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>

          <v-card-text class="mt-5">
            <div v-for="(field, key) in item" :key="key">
              <v-text-field
                v-if="key !== 'id'"
                v-model="item[key]"
                :label="key"
              />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" type="submit">Save</v-btn>
            <v-btn @click="dialog = !dialog">Close</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CrudPage from '../components/CrudPage.vue'
import { createNamespacedHelpers } from 'vuex'
import { SNACK_SUCCESS } from '../store/mutation-types'

const { mapGetters } = createNamespacedHelpers('blacklist')

let emptyItem = {
  email: '',
  characterName: '',
  messenger: '',
  comment: '',
}

export default {
  components: {
    CrudPage,
  },
  data() {
    return {
      options: null,
      dialog: false,
      item: { ...emptyItem },
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Почта', value: 'email' },
        { text: 'Character', value: 'characterName' },
        { text: 'Контакты', value: 'messenger' },
        { text: 'Комментарий', value: 'comment' },
        { text: 'Действия', value: 'actions' },
      ],
    }
  },
  watch: {
    options: function () {
      this.$store.dispatch('blacklist/getItems', this.options)
    },
  },
  computed: {
    ...mapGetters(['blacklist', 'total']),
  },
  methods: {
    createItem() {
      this.item = { ...emptyItem }
      this.dialog = true
    },
    editItem(id) {
      this.$store.dispatch('blacklist/getItem', id).then((resp) => {
        this.item = resp.data
      })
      this.dialog = true
    },
    deleteItem(id) {
      if (confirm('Вы уверены?')) {
        this.$store.dispatch('blacklist/delItem', id).then(() => {
          this.$store.dispatch('blacklist/getItems', this.options)
          this.$store.commit(SNACK_SUCCESS, 'Запись удалёна')
        })
      }
    },
    save() {
      let action = this.item.id ? 'blacklist/updateItem' : 'blacklist/addItem'

      this.$store.dispatch(action, this.item).then(() => {
        this.$store.dispatch('blacklist/getItems', this.options)
        this.$store.commit(SNACK_SUCCESS, 'Запись сохранёна')
        this.dialog = false
        this.item = { ...emptyItem }
      })
    },
  },
}
</script>

<style></style>
