import { api } from '../helpers/http'
import { API_EVENTS } from '@/api'
import {
  SET_EVENTS,
  SET_EVENTS_ITEM,
  SET_EVENTS_TOTAL,
} from '../mutation-types'

export default {
  namespaced: true,
  state: {
    events: [],
    event: {},
    total: -1,
  },
  getters: {
    eventsSelect: ({ events }) =>
      events.map((ev) => ({ text: ev.name, value: ev.id })),
    events: (state) => state.events,
    total: (state) => state.total,
  },
  mutations: {
    [SET_EVENTS]: (state, data) => {
      state.events = data
    },
    [SET_EVENTS_TOTAL]: (state, total) => {
      state.total = parseInt(total)
    },
    [SET_EVENTS_ITEM]: (state, item) => {
      state.event = item
    },
  },
  actions: {
    getItems: ({ commit }, options) => {
      let params = options
        ? {
            page: options.page,
            'per-page': options.itemsPerPage,
          }
        : {}

      return new Promise((resolve, reject) => {
        api
          .get(API_EVENTS, { params: params })
          .then((resp) => {
            commit(SET_EVENTS, resp.data)
            commit(SET_EVENTS_TOTAL, resp.headers['x-pagination-total-count'])
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addItem: (store, item) => {
      return new Promise((resolve, reject) => {
        api
          .post(API_EVENTS, item)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateItem: (store, item) => {
      return new Promise((resolve, reject) => {
        api
          .put(API_EVENTS + '/' + item.id, item)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getItem: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        api
          .get(API_EVENTS + '/' + id)
          .then((resp) => {
            commit(SET_EVENTS_ITEM, resp.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    delItem: (store, id) => {
      return new Promise((resolve, reject) => {
        api
          .delete(API_EVENTS + '/' + id)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
