<template>
  <div class="wrapper">
    <div v-if="product.id">
      <game-item
        :id="product.id"
        :key="product.id"
        :name="product.name"
        :price="product.price"
        :image="product.image"
        selected="true"
        @click="product = {}"
      />
    </div>
    <div v-else>
      <v-text-field
        v-model="productName"
        label="Выберите товар"
        clearable
        placeholder="начните вводить название продукта"
        @keyup="search"
        @click:clear="products = []"
      ></v-text-field>

      <v-card v-if="productsDisplay.length" class="games">
        <v-list two-line>
          <game-item
            v-for="product in productsDisplay"
            :id="product.id"
            :key="product.id"
            :name="product.name"
            :price="product.price"
            :image="product.image"
            @click="select(product)"
          />
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import GameItem from './GameItem.vue'
import { opencartPublic } from '@/store/helpers/http'
import { API_PRODUCT_SEARCH, API_PRODUCT } from '@/api'

export default {
  name: 'GameSelector',
  components: {
    GameItem,
  },
  props: ['value', 'id'],
  data() {
    return {
      product: this.value,
      productName: '',
      products: [],
    }
  },
  computed: {
    productsDisplay() {
      return this.products.map((item) => {
        return {
          id: item.id,
          name: item.name,
          image: item.image,
          price: item.price_formated,
          checked: item.id === this.productId,
        }
      })
    },
  },
  watch: {
    product() {
      this.$emit('input', this.product)
    },
  },
  mounted() {
    if (this.id) {
      opencartPublic.get(`${API_PRODUCT}/${this.id}`).then((resp) => {
        let { id, image, name, price_formated: price } = resp.data.data
        this.product = { id, image, name, price }
      })
    }
  },
  methods: {
    search() {
      opencartPublic
        .get(`${API_PRODUCT_SEARCH}/${this.productName}`)
        .then((resp) => {
          this.products = resp.data.data
        })
    },
    select(product) {
      this.product = product
    },
  },
}
</script>

<style scoped>
.wrapper {
  position: relative;
}
.games {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
