<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="users"
      :server-items-length="total"
      @update:options="getUsers"
    >
      <template #item.active="{ item }">
        <v-simple-checkbox
          v-model="item.active"
          v-ripple
          @input="checked(item, $event)"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('tg')

export default {
  name: 'Tg',
  data() {
    return {
      headers: [
        { text: 'id', value: 'userId' },
        { text: 'chatId', value: 'chatId' },
        { text: 'username', value: 'username' },
        { text: 'active', value: 'active' },
      ],
    }
  },
  computed: {
    ...mapGetters(['users', 'total']),
  },
  methods: {
    ...mapActions(['getUsers', 'updateUser']),
    checked(item, state) {
      item.active = state ? 1 : 0
      this.updateUser(item)
    },
  },
}
</script>
