import { SET_TG_TOTAL, SET_TG } from '../mutation-types'
import { api } from '@/store/helpers/http'
import { API_TG } from '@/api'

export default {
  namespaced: true,
  state: {
    users: [],
    total: -1,
  },
  mutations: {
    [SET_TG]: (state, payload) => {
      state.users = payload
    },
    [SET_TG_TOTAL]: (state, payload) => {
      state.total = parseInt(payload, 10)
    },
  },
  getters: {
    users: (state) => state.users,
    total: (state) => state.total,
  },
  actions: {
    getUsers: ({ commit }, options) => {
      return new Promise((resolve, reject) => {
        let params = options
          ? {
              page: options.page,
              'per-page': options.itemsPerPage,
            }
          : {}

        api
          .get(API_TG, { params: params })
          .then((resp) => {
            commit(
              SET_TG,
              resp.data.map(({ userId, chatId, username, active }) => ({
                userId,
                chatId,
                username,
                active: Boolean(active),
              }))
            )
            commit(SET_TG_TOTAL, resp.headers['x-pagination-total-count'])
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateUser: ({ dispatch }, user) => {
      return new Promise((resolve, reject) => {
        api
          .put(`${API_TG}/${user.userId}`, user)
          .then((resp) => {
            dispatch('getUsers')
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
