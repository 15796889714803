<template>
  <div>
    <crud-page
      :items="eventsToDisplay"
      :total="total"
      :headers="headers"
      :options.sync="options"
      title="Events"
      @create="createItem"
      @edit="editItem"
      @delete="deleteItem"
    />

    <v-dialog v-model="dialog" width="800">
      <v-card>
        <form @submit.prevent="save">
          <v-card-title class="teal darken-4 white--text">
            {{ item.id ? 'Редактирование записи' : 'Создание записи' }}

            <v-btn
              class="ml-auto"
              icon
              depressed
              color="white"
              @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>

          <v-card-text class="mt-5">
            <div>
              <v-text-field v-model="item.name" label="Название эвента" />
            </div>
            <div>
              <v-text-field v-model="item.sort" label="Порядок сортировки" />
            </div>
            <div>
              <v-checkbox v-model="item.hide" label="Скрыть из календаря" />
            </div>
            <game-selector
              :id="item.productId"
              :key="item.id"
              v-model="product"
              class="mb-2"
            />
            <event-fields v-if="item.id" :event-id="item.id" />
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" type="submit">Сохранить</v-btn>
            <v-btn @click="dialog = !dialog">Закрыть</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CrudPage from '@/components/CrudPage.vue'
import EventFields from '@/components/EventFields.vue'
import { createNamespacedHelpers } from 'vuex'
import { SNACK_SUCCESS } from '@/store/mutation-types'
import GameSelector from '../../components/wizard/GameSelector.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('events')

let emptyItem = {
  name: '',
  productId: '',
  sort: 0,
  hide: false
}

export default {
  name: 'Events',
  components: {
    CrudPage,
    EventFields,
    GameSelector,
  },
  data() {
    return {
      product: {},
      options: null,
      dialog: false,
      item: { ...emptyItem },
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Название', value: 'name' },
        { text: 'Порядок', value: 'sort' },
        { text: 'Скрыть в календаре', value: 'hide'},
        { text: 'Действия', value: 'actions' },
      ],
    }
  },
  computed: {
    ...mapGetters(['events', 'total']),
    eventsToDisplay() {
      return this.events
    },
  },
  watch: {
    product() {
      this.item.productId = this.product.id
    },
    options() {
      this.getItems(this.options)
    },
  },
  mounted() {
    // блоки получаем, чтобы отобразить их названия в списке
    this.$store.dispatch('eventblocks/getItems')
  },
  methods: {
    ...mapActions(['addItem', 'updateItem', 'getItems', 'getItem', 'delItem']),
    createItem() {
      this.item = { ...emptyItem }
      this.dialog = true
    },
    editItem(id) {
      this.getItem(id).then((resp) => {
        this.item = resp.data
        this.dialog = true
      })
    },
    deleteItem(id) {
      if (confirm('Вы уверены?')) {
        this.delItem(id).then(() => {
          this.getItems(this.options)
          this.$store.commit(SNACK_SUCCESS, 'Запись удалёна')
        })
      }
    },
    save() {
      if (this.item.id) {
        this.updateItem(this.item).then(() => {
          this.getItems(this.options)
          this.$store.commit(SNACK_SUCCESS, 'Изменения сохранены')
          this.dialog = false
          this.item = { ...emptyItem }
        })
      } else {
        this.addItem(this.item).then((resp) => {
          this.getItems(this.options)
          this.item = resp.data
          this.$store.commit(SNACK_SUCCESS, 'Запись сохранена')
        })
      }
    },
  },
}
</script>

<style></style>
