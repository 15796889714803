<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="dialog = false">
    <v-card>
      <v-form @submit.prevent="$emit('submit', booster)">
        <v-card-title class="teal darken-4 white--text">
          <span>{{ item.name || 'Создать бустера' }}</span>
          <v-spacer />
          <v-btn icon depressed color="white" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="booster.name" label="nickname"></v-text-field>

          <div v-if="item.name" class="d-flex align-center">
            <v-text-field
              v-model="booster.licenseId"
              readonly
              label="license Id"
            ></v-text-field>
            <v-btn icon @click="$emit('refreshToken', booster)"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </div>

          <v-text-field v-model="booster.skype" label="skype"></v-text-field>

          <v-text-field
            v-model="booster.discord"
            label="discord"
          ></v-text-field>

          <v-text-field v-model="booster.info" label="info"></v-text-field>

          <v-text-field
            v-model="booster.priority"
            label="Приоритет при заказе"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" type="submit">Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BoosterDialog',
  props: ['item', 'value'],
  data() {
    return {
      dialog: this.value,
      booster: { ...this.item },
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    },
  },
}
</script>
