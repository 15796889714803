<template>
  <v-container fill-height>
    <v-row
      v-for="(menu, key) in filteredMenu"
      :key="key"
      justify="center"
      align="stretch"
    >
      <v-col
        v-for="(submenu, subkey) in menu.items"
        :key="subkey"
        class="d-flex"
      >
        <PanelItem
          :title="submenu.name"
          :icon="submenu.icon"
          button="Перейти"
          :link="submenu.link"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { menus } from '../menu.js'
import PanelItem from '@/components/PanelItem'
export default {
  name: 'Panel',
  components: {
    PanelItem,
  },
  data() {
    return {
      menus: [...menus],
    }
  },
  computed: {
    filteredMenu() {
      let filtered = this.menus.filter((el) => this.checkGroup(el.groups))
      filtered.forEach((menu) => {
        menu.items = menu.items.filter((el) => this.checkGroup(el.groups))
      })
      return filtered
    },
  },
  methods: {
    checkGroup(groups) {
      if (groups.find((el) => el == this.$store.getters.group)) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
