import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import { SET_TOKEN } from './store/mutation-types'
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)

// As a plugin
import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.config.productionTip = false

const token = localStorage.getItem('x-token')

if (token) {
  const group = localStorage.getItem('group')
  const ocToken = localStorage.getItem('oc-token')

  store.commit(SET_TOKEN, {
    'x-token': token,
    'oc-token': ocToken,
    group: group,
  })
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
