export const API_LOGIN = '/login'
export const API_LOGOUT = '/logout'
export const API_RENEW_TOKEN = '/renew-token'
export const API_USERS = '/users'
export const API_USER_GROUPS = '/groups'
export const API_BLACKLIST = '/blacklist'
export const API_EVENTBLOCKS = '/event-block'
export const API_EVENTS = '/event'
export const API_EVENTFIELDS = '/event/fields'
export const API_COUPONS = '/coupons'
export const API_ORDERS_DETAIL = '/orders/details'
export const API_EVENT_ORDERS = '/orders/event'
export const API_ORDERS = '/orders'
export const API_INIT = '/init'
export const API_CALENDAR = '/event-schedule'
export const API_BL_SEARCH = '/blacklist-search'
export const API_ORDER_SEARCH = '/order_search'
export const API_CUSTOM_FIELDS = '/customfields'
export const API_ORDER_ITEMS = '/orders/order_items'
export const API_PRODUCT_SEARCH = '/products/search'
export const API_PRODUCT = '/products'
export const API_REFUND = '/refund'
export const API_PAYOUT = '/payout'
export const API_PAYOUT_CALCULATE = '/payout/calculate'
export const API_BOOSTER = '/booster'
export const API_HMB = '/hmb/ccc'
export const API_HMB_BOOSTERS_ONLINE = '/hmb/online/boosters'
export const API_HMB_CUSTOMERS_ONLINE = '/hmb/online/customers'
export const API_TG = '/tg-bot'
