var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.opts,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': [25, 50, 100],
      }},on:{"update:options":[function($event){_vm.opts=$event},function($event){return _vm.$emit('update:options', _vm.opts)}]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('nobr',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)]}},{key:"item.active",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.active ? 'green' : 'red'}},[_vm._v("mdi-record")])]}},{key:"item.boosterOnline",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.boosterOnline ? 'green' : 'red'}},[_vm._v("mdi-record")])]}},{key:"item.customerOnline",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.customerOnline ? 'green' : 'red'}},[_vm._v("mdi-record")])]}}])})],1),(!_vm.hideNew)?_c('v-btn',{attrs:{"color":"red","dark":"","bottom":"","right":"","fab":"","fixed":""},on:{"click":function($event){return _vm.$emit('create')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }