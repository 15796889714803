<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="close">
    <v-card>
      <form @submit.prevent="$emit('update-refund', refund)">
        <v-card-title class="teal darken-4 white--text">
          Рефанд №{{ refund.id }}
          <v-spacer />
          <v-btn icon depressed color="white" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <v-switch
            v-model="refund.status"
            :label="refund.status ? 'Выплачен' : 'Не выплачен'"
          ></v-switch>

          <v-chip class="mb-4"
            >Дата создания заказа: {{ order.date_added }}</v-chip
          >

          <div class="text-h6 mb-2">Продукты:</div>

          <v-data-table
            v-if="productsData"
            :headers="headers"
            :items="productsData"
            class="mb-4"
            hide-default-footer
          >
            <template #item.completed="{ item }">
              <v-chip
                :color="item.completed === true ? 'green' : 'red'"
                class="white--text"
              >
                {{ item.completed ? 'Завершен' : 'Не завершен' }}
              </v-chip>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="refund.refundSum"
                :suffix="refund.refundCurrency"
                label="Сумма"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="refund.refundCurrency"
                label="Валюта"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="refund.refundScreen"
                label="Скриншот рефанда"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="refund.userScreen"
                label="Скриншот переписки"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-textarea v-model="refund.comment" label="Причина рефанда" />
        </v-container>

        <v-card-actions>
          <v-btn color="primary" type="submit">Обновить</v-btn>
          <v-btn @click="close">Закрыть</v-btn>
          <v-spacer />
          <v-btn
            color="pink lighten"
            class="white--text"
            @click="$emit('delete-refund', refund.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RefundDialog',
  props: ['refund', 'order', 'dialog', 'close'],
  data() {
    return {
      headers: [
        { text: 'ID', value: 'externalId' },
        { text: 'Имя', value: 'name' },
        { text: 'Booster ID', value: 'boosterId' },
        { text: 'Завершен', value: 'completed' },
        { text: 'Product ID', value: 'productId' },
        { text: 'Количество', value: 'quantity' },
      ],
      productsData: null,
    }
  },
  mounted() {
    this.getProductsData()
  },
  methods: {
    getProductsData() {
      this.productsData = this.order.products
        // так как рефунд привязан только к 1 товару, то показываем только товар, для которого рефунд создан.
        .filter(({ order_product_id }) => {
          return order_product_id === this.refund.orderItemId
        })
        .map(
          // тут нам нужен уникальный айди товара в заказе. а не просто id товара
          ({
            name,
            boosterId,
            completed,
            order_product_id: productId,
            quantity,
            option,
            external_id: externalId,
          }) => ({
            name,
            boosterId,
            completed,
            productId,
            quantity,
            option,
            externalId,
          })
        )
    },
  },
}
</script>
