import { opencart } from '@/store/helpers/http'
import { API_COUPONS } from '@/api'
import moment from 'moment'

import {
  SET_COUPONS_LIST,
  SET_COUPON_NAME,
  SET_COUPON_CODE,
  SET_COUPON_DISCOUNT,
  SET_COUPON_START,
  SET_COUPON_END,
  SET_COUPON_STATUS,
  SET_COUPON_ID,
  SET_COUPONS_TOTAL,
  SET_COUPONS_OPTIONS,
  SET_COUPON_TYPE,
  SET_COUPON_TOTAL,
  SET_COUPON_USES_TOTAL,
  SET_COUPON_USES_CUSTOMER,
  SNACK_SUCCESS,
  SNACK_ERROR,
} from '@/store/mutation-types'

export default {
  namespaced: true,
  state: {
    headers: [
      {
        text: 'Название',
        value: 'name',
      },
      { text: 'Код', value: 'code' },
      { text: 'Скидка', value: 'discount' },
      { text: 'Дата начала', value: 'date_start' },
      { text: 'Дата конца', value: 'date_end' },
      { text: 'Статус', value: 'status' },
      { text: 'Действия', value: 'action' },
    ],
    couponsList: [],
    options: {},
    name: '',
    code: 0,
    discount: 0,
    start: '',
    end: '',
    status: 0,
    id: 0,
    total: 0,
    type: '',
    couponTotal: '',
    usesTotal: 0,
    usesCustomer: 0,
    searchCode: ''
  },
  mutations: {
    [SET_COUPONS_LIST](state, payload) {
      state.couponsList = payload
    },
    [SET_COUPON_NAME](state, payload) {
      state.name = payload
    },
    [SET_COUPON_CODE](state, payload) {
      state.code = payload
    },
    [SET_COUPON_DISCOUNT](state, payload) {
      state.discount = payload
    },
    [SET_COUPON_START](state, payload) {
      state.start = payload
    },
    [SET_COUPON_END](state, payload) {
      state.end = payload
    },
    [SET_COUPON_STATUS](state, payload) {
      state.status = payload
    },
    [SET_COUPON_ID](state, payload) {
      state.id = payload
    },
    [SET_COUPONS_TOTAL](state, payload) {
      state.total = payload
    },
    [SET_COUPONS_OPTIONS](state, payload) {
      state.options = payload
    },
    [SET_COUPON_TYPE](state, payload) {
      state.type = payload
    },
    [SET_COUPON_TOTAL](state, payload) {
      state.couponTotal = payload
    },
    [SET_COUPON_USES_TOTAL](state, payload) {
      state.usesTotal = payload
    },
    [SET_COUPON_USES_CUSTOMER](state, payload) {
      state.usesCustomer = payload
    },
  },
  actions: {
    setCouponName({ commit }, payload) {
      commit(SET_COUPON_NAME, payload)
    },
    setCouponCode({ commit }, payload) {
      commit(SET_COUPON_CODE, payload)
    },
    setCouponDiscount({ commit }, payload) {
      commit(SET_COUPON_DISCOUNT, payload)
    },
    setCouponStart({ commit }, payload) {
      commit(SET_COUPON_START, payload)
    },
    setCouponEnd({ commit }, payload) {
      commit(SET_COUPON_END, payload)
    },
    setCouponStatus({ commit }, payload) {
      commit(SET_COUPON_STATUS, payload)
    },
    setCouponType({ commit }, payload) {
      commit(SET_COUPON_TYPE, payload)
    },
    setCouponTotal({ commit }, payload) {
      commit(SET_COUPON_TOTAL, payload)
    },
    setCouponUsesTotal({ commit }, payload) {
      commit(SET_COUPON_USES_TOTAL, payload)
    },
    setCouponUsesCustomer({ commit }, payload) {
      commit(SET_COUPON_USES_CUSTOMER, payload)
    },
    async searchCoupon({ commit }, searchCode) {
      try {
        const response = await opencart.get(
          `${API_COUPONS}?code=${searchCode}`
        )
        const {
          data: { total: total },
        } = response
        const {
          data: { data: coupons },
        } = response

        commit(SET_COUPONS_TOTAL, total)
        commit(SET_COUPONS_LIST, coupons)
      } catch (error) {
        console.error(error)
      }
    },
    async getCouponsList({ commit }, options) {
      const { itemsPerPage, page } = options
      try {
        const response = await opencart.get(
          `${API_COUPONS}/limit/${itemsPerPage}/page/${page}`
        )
        const {
          data: { total: total },
        } = response
        const {
          data: { data: coupons },
        } = response

        commit(SET_COUPONS_OPTIONS, options)
        commit(SET_COUPONS_TOTAL, total)
        commit(SET_COUPONS_LIST, coupons)
      } catch (error) {
        console.error(error)
      }
    },
    async getCoupon({ commit }, id) {
      try {
        const response = await opencart.get(`${API_COUPONS}/${id}`)

        const {
          data: { data: coupon },
        } = response

        const {
          name,
          code,
          discount,
          date_start: start,
          date_end: end,
          status,
          coupon_id: couponId,
          type,
          total: couponTotal,
          uses_total: usesTotal,
          uses_customer: usesCustomer,
        } = coupon

        commit(SET_COUPON_NAME, name)
        commit(SET_COUPON_CODE, code)
        commit(SET_COUPON_DISCOUNT, discount)
        commit(
          SET_COUPON_START,
          moment(start, 'DD/MM/YYYY').format('DD.MM.YYYY')
        )
        commit(SET_COUPON_END, moment(end, 'DD/MM/YYYY').format('DD.MM.YYYY'))
        commit(SET_COUPON_ID, couponId)
        commit(SET_COUPON_STATUS, status)
        commit(SET_COUPON_TYPE, type)
        commit(SET_COUPON_TOTAL, couponTotal)
        commit(SET_COUPON_USES_TOTAL, usesTotal)
        commit(SET_COUPON_USES_CUSTOMER, usesCustomer)
      } catch (error) {
        console.error(error)
      }
    },
    async setCoupon({ commit, dispatch, state }, edit) {
      try {
        const {
          name,
          code,
          discount,
          start,
          end,
          status,
          id,
          options,
          type,
          couponTotal: total,
          usesTotal: uses_total,
          usesCustomer: uses_customer,
        } = state

        const date_start = moment(start, 'DD.MM.YYYY').format('YYYY-MM-DD')
        const date_end = moment(end, 'DD.MM.YYYY').format('YYYY-MM-DD')

        const data = {
          name,
          code,
          discount,
          date_start,
          date_end,
          status,
          type,
          total,
          uses_total,
          uses_customer,
        }

        if (edit) {
          await opencart.put(`${API_COUPONS}/${id}`, data)
          commit(SNACK_SUCCESS, 'Купон успешно изменен', { root: true })
        } else {
          await opencart.post(API_COUPONS, data)
          commit(SNACK_SUCCESS, 'Купон успешно добавлен', { root: true })
        }

        await dispatch('getCouponsList', options)
      } catch (error) {
        const {
          response: {
            data: { error: messages },
          },
        } = error
        commit(SNACK_ERROR, messages.join('<br>'), { root: true })

        console.error(error)
      }
    },
    async deleteCoupon({ commit, dispatch, state }, id) {
      try {
        const { options } = state

        await opencart.delete(`${API_COUPONS}/${id}`)
        commit(SNACK_SUCCESS, 'Купон успешно удален', { root: true })
        await dispatch('getCouponsList', options)
      } catch (error) {
        const {
          response: {
            data: { error: messages },
          },
        } = error
        commit(SNACK_ERROR, messages.join('<br>'), { root: true })

        console.error(error)
      }
    },
    clearCoupon({ commit }) {
      commit(SET_COUPON_NAME, '')
      commit(SET_COUPON_CODE, 0)
      commit(SET_COUPON_DISCOUNT, 0)
      commit(SET_COUPON_START, '')
      commit(SET_COUPON_END, '')
      commit(SET_COUPON_ID, 0)
      commit(SET_COUPON_STATUS, 0)
    },
  },
}
