import { api } from '../helpers/http'
import { API_BLACKLIST } from '@/api'
import {
  SET_BLACKLIST,
  SET_BLACKLIST_ITEM,
  SET_BLACKLIST_TOTAL,
} from '../mutation-types'

export default {
  namespaced: true,
  state: {
    blacklistItems: [],
    blacklistItem: {},
    total: -1,
  },
  mutations: {
    [SET_BLACKLIST]: (state, data) => {
      state.blacklistItems = data
    },
    [SET_BLACKLIST_TOTAL]: (state, total) => {
      state.total = parseInt(total)
    },
    [SET_BLACKLIST_ITEM]: (state, item) => {
      state.blacklistItem = item
    },
  },
  getters: {
    blacklist: (state) => state.blacklistItems,
    total: (state) => state.total,
  },
  actions: {
    getItems: ({ commit }, options) => {
      let params = options
        ? {
            page: options.page,
            'per-page': options.itemsPerPage,
          }
        : {}

      return new Promise((resolve, reject) => {
        api
          .get(API_BLACKLIST, { params: params })
          .then((resp) => {
            commit(SET_BLACKLIST, resp.data)
            commit(
              SET_BLACKLIST_TOTAL,
              resp.headers['x-pagination-total-count']
            )
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addItem: (store, item) => {
      return new Promise((resolve, reject) => {
        api
          .post(API_BLACKLIST, item)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateItem: (store, item) => {
      return new Promise((resolve, reject) => {
        api
          .put(API_BLACKLIST + '/' + item.id, item)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getItem: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        api
          .get(API_BLACKLIST + '/' + id)
          .then((resp) => {
            commit(SET_BLACKLIST_ITEM, resp.data)
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    delItem: (store, id) => {
      return new Promise((resolve, reject) => {
        api
          .delete(API_BLACKLIST + '/' + id)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
