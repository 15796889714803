import {
  SET_EVENTFIELDS,
  SET_EVENTFIELDS_EVENT,
  SET_EVENTFIELDS_TOTAL,
} from '../mutation-types'
import { api } from '../helpers/http'
import { API_EVENTS, API_EVENTFIELDS } from '@/api'

export default {
  namespaced: true,
  state: {
    event: 0,
    eventfieldsItems: [],
    total: -1,
  },
  mutations: {
    [SET_EVENTFIELDS]: (state, data) => {
      state.eventfieldsItems = data
    },
    [SET_EVENTFIELDS_TOTAL]: (state, total) => {
      state.total = parseInt(total)
    },
    [SET_EVENTFIELDS_EVENT]: (state, eventId) => {
      state.event = eventId
    },
  },
  getters: {
    eventfields: (state) => state.eventfieldsItems,
    total: (state) => state.total,
    event: (state) => state.event,
  },
  actions: {
    setEvent({ commit }, eventId) {
      commit(SET_EVENTFIELDS_EVENT, eventId)
    },
    getEventFields({ commit, getters }, options) {
      let params = options
        ? {
            'per-page': options.itemsPerPage,
            page: options.page,
          }
        : {}

      return new Promise((resolve, reject) => {
        api
          .get(`${API_EVENTS}/${getters.event}/fields`, { params: params })
          .then((resp) => {
            commit(SET_EVENTFIELDS, resp.data)
            commit(
              SET_EVENTFIELDS_TOTAL,
              resp.headers['x-pagination-total-count']
            )
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addEventField({ getters }, data) {
      return new Promise((resolve, reject) => {
        api
          .post(`${API_EVENTS}/${getters.event}/fields`, data)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addEventFieldByEvent(store, data) {
      return new Promise((resolve, reject) => {
        api
          .post(`${API_EVENTS}/${data.eventId}/fields`, data.item)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    delEventField(store, id) {
      return new Promise((resolve, reject) => {
        api
          .delete(`${API_EVENTFIELDS}/${id}`)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
