import { SNACK_HIDE, SNACK_ERROR, SNACK_SUCCESS } from '../mutation-types'

export default {
  state: {
    snackText: '',
    snackError: false,
    snackShow: false,
  },
  mutations: {
    [SNACK_ERROR]: (state, text) => {
      state.snackText = text
      state.snackError = true
      state.snackShow = true
    },
    [SNACK_SUCCESS]: (state, text) => {
      state.snackText = text
      state.snackError = false
      state.snackShow = true
    },
    [SNACK_HIDE]: (state) => {
      state.snackShow = false
    },
  },
  getters: {
    snackColor: ({ snackError }) => (snackError ? 'red' : 'green'),
    snackShow: ({ snackShow }) => snackShow,
    snackText: ({ snackText }) => snackText,
  },
}
