var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.complaintsList},on:{"update:options":_vm.getComplaintsList},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("dateFormat")(item.date)))])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[_vm._v(_vm._s(item.comment))])]}},{key:"item.resolved",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.resolved ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.resolved ? 'Решён' : 'Не решён')+" ")])]}},{key:"item.contacts",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","href":("mailto:" + (item.contacts.email))}},[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","color":"primary","href":("skype:" + (item.contacts.skype) + "?call")}},[_c('v-icon',[_vm._v("mdi-skype")])],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],on:{"input":function($event){return _vm.checked(item, $event)}},model:{value:(item.resolved),callback:function ($$v) {_vm.$set(item, "resolved", $$v)},expression:"item.resolved"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }