import { createNamespacedHelpers } from 'vuex'
import vModelBinding from '@/helpers/vModelBinding'

const { mapState, mapActions } = createNamespacedHelpers('coupons')
const models = [
  { state: 'name', action: 'setCouponName' },
  { state: 'code', action: 'setCouponCode' },
  { state: 'discount', action: 'setCouponDiscount' },
  { state: 'start', action: 'setCouponStart' },
  { state: 'end', action: 'setCouponEnd' },
  { state: 'status', action: 'setCouponStatus' },
  { state: 'type', action: 'setCouponType' },
  { state: 'couponTotal', action: 'setCouponTotal' },
  { state: 'usesTotal', action: 'setCouponUsesTotal' },
  { state: 'usesCustomer', action: 'setCouponUsesCustomer' },
]
const states = models.map((item) => item.state)
const actions = models.map((item) => item.action)

export default {
  computed: {
    ...vModelBinding(models, 'Val'),
    ...mapState(states),
  },
  methods: {
    ...mapActions(actions),
  },
}
