import { API_LOGIN, API_LOGOUT, API_RENEW_TOKEN } from '@/api'
import { api, opencart } from '../helpers/http'
import {
  SET_TOKEN,
  SET_AUTH_ERROR,
  SNACK_SUCCESS,
  SNACK_ERROR,
  SET_OC_TOKEN,
} from '../mutation-types'

export default {
  state: {
    xToken: '',
    ocToken: '',
    group: '',
  },
  mutations: {
    [SET_TOKEN]: (state, data) => {
      state.xToken = data['x-token']
      state.ocToken = data['oc-token']
      state.group = data['group']

      localStorage.setItem('x-token', data['x-token'])
      localStorage.setItem('oc-token', data['oc-token'])
      localStorage.setItem('group', data['group'])

      api.defaults.headers.common['X-API-Key'] = data['x-token']
      opencart.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${data['oc-token']}`
    },
    [SET_AUTH_ERROR]: (state) => {
      state.xToken = ''
      state.ocToken = ''

      localStorage.removeItem('x-token')
      localStorage.removeItem('oc-token')
      localStorage.removeItem('group')

      api.defaults.headers.common['X-API-Key'] = ''
      opencart.defaults.headers.common['Authorization'] = ''
    },
    [SET_OC_TOKEN]: (state, token) => {
      state.ocToken = token
      opencart.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
  },
  actions: {
    auth: ({ commit }, data) => {
      return new Promise((resolve, reject) => {
        api
          .post(API_LOGIN, data)
          .then((resp) => {
            commit(SET_TOKEN, resp.data)
            commit(SNACK_SUCCESS, 'Успешная авторизация')
            resolve(resp)
          })
          .catch((err) => {
            commit(SET_AUTH_ERROR)
            commit(SNACK_ERROR, err.response.data[0].message)
            reject(err)
          })
      })
    },
    logout: ({ commit }) => {
      commit(SET_AUTH_ERROR)
      return new Promise((resolve, reject) => {
        api
          .get(API_LOGOUT)
          .then((resp) => {
            resolve(resp)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    renewToken: ({ commit }) => {
      return new Promise((resolve, reject) => {
        api
          .get(API_RENEW_TOKEN)
          .then((resp) => {
            commit(SET_OC_TOKEN, resp.data['oc-token'])
            resolve(resp)
          })
          .catch((err) => {
            commit(SET_AUTH_ERROR)
            reject(err)
          })
      })
    },
  },
  getters: {
    group: ({ group }) => group,
  },
}
