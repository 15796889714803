<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="dialog = false">
    <v-card>
      <v-card-title class="teal darken-4 white--text">
        <span>Dispute {{ item.dispute_id }}</span>
        <v-spacer />
        <v-btn icon depressed color="white" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-simple-table v-if="item.disputed_transactions">
          <template #default>
            <thead>
              <tr>
                <th>Номер заказа</th>
                <th>Дата оплаты</th>
                <th>Сумма возврата</th>
                <th>Контакты</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="transaction in item.disputed_transactions"
                :key="transaction.create_time"
              >
                <td>
                  {{ transaction.custom || orderId }}
                </td>
                <td>
                  {{ transaction.create_time | formatDate }}
                </td>
                <td>
                  {{ transaction.gross_amount.value }}
                  {{ transaction.gross_amount.currency_code }}
                </td>
                <td v-if="order.telephone && order.email">
                  <v-btn
                    v-if="order.telephone.indexOf('skype:') === 0"
                    icon
                    color="blue"
                    target="_blank"
                    @click="copy(order.telephone)"
                    ><v-icon>mdi-skype</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    icon
                    color="purple"
                    target="_blank"
                    @click="copy(order.telephone)"
                    ><v-icon>mdi-discord</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    color="red"
                    :href="'mailto:' + order.email"
                    target="_blank"
                    ><v-icon>mdi-gmail</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-skeleton-loader v-else type="table-tbody"></v-skeleton-loader>
        <h3 v-if="item.messages" class="mt-4">Сообщения</h3>
        <v-list>
          <v-list-item v-for="msg in item.messages" :key="msg.time_posted">
            <v-list-item-content>
              <v-list-item-subtitle
                >{{ msg.posted_by }} @
                {{ msg.time_posted | formatDate }}</v-list-item-subtitle
              >
              <v-list-item-title>{{ msg.content }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { fromDB, DATE_FORMAT } from '@/helpers/dates'
import { API_ORDER_SEARCH } from '@/api'
import { opencart } from '@/store/helpers/http'
import { SNACK_SUCCESS } from '@/store/mutation-types'
export default {
  name: 'DisputeDialog',
  filters: {
    formatDate(date) {
      return fromDB(date, DATE_FORMAT)
    },
  },
  props: ['item', 'value'],
  data() {
    return {
      dialog: this.value,
      orders: [],
      orderId: 0,
      order: {},
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    },
    item() {
      // это для страйпа. Ищем заказ по платежу
      if (this.item.payment_intent) {
        opencart
          .post(API_ORDER_SEARCH, { search: this.item.payment_intent })
          .then((resp) => {
            if (resp.data.data.length) {
              this.orderId = resp.data.data[0].order_id
              this.order = resp.data.data[0]
            }
          })

        // это для пейпала - просто забираем заказы по id
      } else if (this.item.disputed_transactions) {
        this.item.disputed_transactions.forEach((trans) => {
          this.getOrder(trans.custom)
        })
      }
    },
  },
  methods: {
    getOrder(id) {
      this.$store.dispatch('orders/getOrder', id).then((resp) => {
        this.order = resp.data.data
      })
    },
    copy(text) {
      navigator.clipboard.writeText(text)
      this.$store.commit(SNACK_SUCCESS, `Скопировано "${text}"`)
    },
  },
}
</script>

<style></style>
