<template>
  <v-container>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-form @submit.prevent="save">
          <v-card-title class="teal darken-4 white--text">
            {{ id ? 'Редактирование купона' : 'Создание купона' }}
            <v-btn
              class="ml-auto"
              icon
              depressed
              color="white"
              @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text class="mt-5">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="nameVal"
                  label="Название купона"
                  required
                ></v-text-field>
                <div class="d-flex">
                  <v-text-field
                    v-model="codeVal"
                    label="Код для отправки клиенту"
                    required
                  ></v-text-field>
                  <v-btn icon @click="generate">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </div>
                <v-text-field
                  v-model="discountVal"
                  label="Скидка"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="startVal"
                  v-mask="'##.##.####'"
                  label="Дата начала действия"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="endVal"
                  v-mask="'##.##.####'"
                  label="Дата окончания действия"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="statusVal"
                  :items="items"
                  item-text="state"
                  item-value="val"
                  label="Статус"
                  required
                ></v-select>
                <v-select
                  v-model="typeVal"
                  :items="percentage"
                  label="Тип скидки"
                  required
                ></v-select>
                <v-text-field
                  v-model="couponTotalVal"
                  label="Сумма заказа, от которой действует скидка"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="usesTotalVal"
                  label="Сколько раз можно использовать"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="usesCustomerVal"
                  label="Сколько раз может использовать 1 покупатель"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            ><v-btn color="primary" type="submit"> Сохранить </v-btn
            ><v-btn @click="close"> Закрыть </v-btn></v-card-actions
          >
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmModal" max-width="500px">
      <v-card>
        <v-card-title>Удалить купон?</v-card-title>
        <v-card-actions
          ><v-btn color="success" class="mr-4" @click="deleteItem">
            Удалить </v-btn
          ><v-btn color="error" @click="close"> Отмена </v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
    <form @submit.prevent="searchCoupon(searchCode)" class="d-flex align-center">
      <v-text-field 
      v-model="searchCode" 
      clearable 
      @click:clear="getCouponsList(options)"
      placeholder="Введите код купона"
      ></v-text-field>
      <v-btn type="submit" size="sm" class="ml-5">Найти</v-btn>
    </form>
    <v-data-table
      item-key="code"
      :headers="headers"
      :server-items-length="total"
      :items="couponsList"
      :items-per-page="10"
      :options.sync="options"
      @update:options="getCouponsList"
    >
      <template v-slot:item.code="{ item }">
        <div
          v-clipboard="() => item.code"
          v-clipboard:success="copycode"
          class="copy-to-clipboard"
        >
          {{ item.code }}
        </div>
      </template>
      <template v-slot:item.date_start="{ item }">
        {{ formatDate(item.date_start) }}
      </template>
      <template v-slot:item.date_end="{ item }">
        {{ formatDate(item.date_end) }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ !!item.status ? 'Активен' : 'Неактивен' }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon class="mr-2" @click="editItem(item.coupon_id)">
          mdi-pencil
        </v-icon>
        <v-icon @click="confirmDelete(item.coupon_id)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-btn color="red" dark bottom right fab fixed @click="newItem">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('coupons')
import coupons from '@/mixins/coupons'
import moment from 'moment'
import { SNACK_SUCCESS } from '../store/mutation-types'

export default {
  name: 'Coupons',
  mixins: [coupons],
  data() {
    return {
      dialog: false,
      items: [
        { state: 'Неактивен', val: 0 },
        { state: 'Активен', val: 1 },
      ],
      percentage: [
        { text: 'Процент', value: 'P' },
        { text: 'Фиксированная сумма', value: 'F' },
      ],
      edit: false,
      options: {},
      confirmModal: false,
      searchCode: '',
      id: 0,
    }
  },
  computed: {
    ...mapState(['headers', 'couponsList', 'total']),
  },
  methods: {
    ...mapActions([
      'getCouponsList',
      'getCoupon',
      'setCoupon',
      'clearCoupon',
      'deleteCoupon',
      'searchCoupon'
    ]),
    copycode() {
      this.$store.commit(SNACK_SUCCESS, 'Код скопирован')
    },
    formatDate(date) {
      return moment(date, 'DD/MM/YYYY').format('DD.MM.YYYY')
    },
    editItem(id) {
      this.getCoupon(id)
      this.edit = true
      this.dialog = true
    },
    newItem() {
      this.clearCoupon()
      this.edit = false
      this.dialog = true
      this.generate()
    },
    confirmDelete(id) {
      this.id = id
      this.confirmModal = true
    },
    deleteItem() {
      this.deleteCoupon(this.id)
      this.close()
    },
    close() {
      this.clearCoupon()
      this.confirmModal = false
      this.dialog = false
    },
    save() {
      this.setCoupon(this.edit)
      this.close()
    },
    generate() {
      let length = 8
      var result = []
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        )
      }
      this.codeVal = result.join('')
    },
  },
}
</script>

<style lang="scss" scoped></style>
