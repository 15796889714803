import { api } from '@/store/helpers/http'
import {
  SET_BOOSTER,
  SET_BOOSTER_LIST,
  SET_BOOSTER_TOTAL,
  ADD_ONE_BOOSTER,
} from '@/store/mutation-types'
import { API_BOOSTER } from '../../api'

export default {
  namespaced: true,
  state: {
    booster: {},
    boosters: [],
    total: -1,
  },
  mutations: {
    [SET_BOOSTER]: (state, data) => {
      state.booster = data
    },
    [SET_BOOSTER_LIST]: (state, data) => {
      state.boosters = data
    },
    [SET_BOOSTER_TOTAL]: (state, total) => {
      state.total = parseInt(total)
    },
    [ADD_ONE_BOOSTER]: (state, data) => {
      state.boosters.push(data)
    },
  },
  getters: {
    boosters: ({ boosters }) => boosters,
    total: ({ total }) => total,
  },
  actions: {
    getItem: ({ commit }, item) =>
      new Promise((res, rej) => {
        api
          .get(API_BOOSTER + '/' + item.id)
          .then((resp) => {
            commit(SET_BOOSTER, resp.data)
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      }),
    refreshToken: ({ commit }, item) =>
      new Promise((res, rej) => {
        api
          .get(API_BOOSTER + '/generate-uid/' + item.id)
          .then((resp) => {
            commit(SET_BOOSTER, resp.data)
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      }),
    getItems: ({ commit }, options) => {
      let params = options
        ? {
            page: options.page,
            'per-page': options.itemsPerPage,
          }
        : {
            'per-page': 999,
          }

      return new Promise((res, rej) => {
        api
          .get(API_BOOSTER, { params: params })
          .then((resp) => {
            commit(SET_BOOSTER_LIST, resp.data)
            commit(SET_BOOSTER_TOTAL, resp.headers['x-pagination-total-count'])
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    addItem: ({ commit }, item) => {
      return new Promise((res, rej) => {
        api
          .post(API_BOOSTER, item)
          .then((resp) => {
            commit(ADD_ONE_BOOSTER, resp.data)
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    delItem: (x, item) => {
      return new Promise((res, rej) => {
        api
          .delete(API_BOOSTER + '/' + item.id)
          .then((resp) => {
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    updateItem: (x, item) => {
      return new Promise((res, rej) => {
        api
          .put(API_BOOSTER + '/' + item.id, item)
          .then((resp) => {
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
  },
}
