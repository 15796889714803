import { api } from '@/store/helpers/http'
import {
  API_HMB,
  API_HMB_BOOSTERS_ONLINE,
  API_HMB_CUSTOMERS_ONLINE,
} from '@/api'
import {
  SET_HMB_CCC_LIST,
  SET_HMB_TOTAL,
  SET_HMB_CCC,
  SET_ONLINE_BOOSTERS,
  SET_ONLINE_CUSTOMERS,
} from '@/store/mutation-types'

export default {
  namespaced: true,
  state: {
    cccs: [],
    ccc: {},
    onlineBoosters: [],
    onlineCustomers: [],
    total: -1,
  },
  mutations: {
    [SET_HMB_CCC_LIST]: (state, payload) => {
      state.cccs = payload
    },
    [SET_HMB_TOTAL]: (state, payload) => {
      state.total = payload
    },
    [SET_HMB_CCC]: (state, payload) => {
      state.ccc = payload
    },
    [SET_ONLINE_BOOSTERS]: (state, payload) => {
      state.onlineBoosters = payload
    },
    [SET_ONLINE_CUSTOMERS]: (state, payload) => {
      state.onlineCustomers = payload
    },
  },
  actions: {
    getOnline: async ({ commit }) => {
      const boosters = new Promise((res, rej) => {
        api
          .get(API_HMB_BOOSTERS_ONLINE)
          .then((resp) => {
            commit(SET_ONLINE_BOOSTERS, resp.data)
            res(resp.data)
          })
          .catch((err) => rej(err))
      })
      const customers = new Promise((res, rej) => {
        api
          .get(API_HMB_CUSTOMERS_ONLINE)
          .then((resp) => {
            commit(SET_ONLINE_CUSTOMERS, resp.data)
            res(resp.data)
          })
          .catch((err) => rej(err))
      })
      return Promise.all([boosters, customers])
    },
    getItems: async ({ commit, dispatch /*, getters*/ }, options) => {
      let params = options
        ? {
            page: options.page,
            'per-page': options.itemsPerPage,
          }
        : {}

      await dispatch('getOnline')

      return new Promise((res, rej) => {
        api
          .get(API_HMB, { params })
          .then((resp) => {
            commit(SET_HMB_CCC_LIST, resp.data)
            commit(SET_HMB_TOTAL, resp.headers['x-pagination-total-count'])
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    getItem: ({ commit }, id) => {
      return new Promise((res, rej) => {
        api
          .get(API_HMB + '/' + id)
          .then((resp) => {
            commit(SET_HMB_CCC, resp.data)
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    toggleActive: ({ commit, dispatch }, { id, active }) => {
      return new Promise((res, rej) => {
        api
          .put(API_HMB + '/' + id, { active })
          .then((resp) => {
            commit(SET_HMB_CCC, resp.data)
            dispatch('getItems')
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    delItem: ({ commit, dispatch }, id) => {
      return new Promise((res, rej) => {
        api
          .delete(API_HMB + '/' + id)
          .then((resp) => {
            commit(SET_HMB_CCC, resp.data)
            dispatch('getItems')
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
    addItem: ({ commit, dispatch }, { jobId, boosterId }) => {
      return new Promise((res, rej) => {
        api
          .post(API_HMB, { jobId, boosterId })
          .then((resp) => {
            commit(SET_HMB_CCC, resp.data)
            dispatch('getItems')
            res(resp)
          })
          .catch((err) => {
            rej(err)
          })
      })
    },
  },
  getters: {
    items: (state) => state.cccs,
    total: (state) => parseInt(state.total),
    ccc: (state) => state.ccc,
    itemsOnline: (state) => {
      const onlineBoosters = state.onlineBoosters.map((item) => ({
        ...item,
        boosterOnline: true,
        customerOnline: state.onlineCustomers.indexOf(item) > -1,
      }))

      const onlineCustomers = state.onlineCustomers.map((item) => ({
        ...item,
        boosterOnline: state.onlineBoosters.indexOf(item) > -1,
        customerOnline: true,
      }))

      return [...onlineBoosters, ...onlineCustomers].filter(
        (value, index, self) => self.indexOf(value) === index
      )
    },
  },
}
