import { api } from '../helpers/http'

import { API_REFUND } from '@/api'
import {
  SET_REFUNDS,
  SNACK_SUCCESS,
  SET_REFUNDS_TOTAL,
} from '@/store/mutation-types'

export default {
  namespaced: true,
  state: {
    refunds: [],
    total: -1,
  },
  actions: {
    getRefunds: ({ commit }, options) => {
      /// мы ловим постраничную навигацию из компонета таблицы
      // и запрашиваем с сервера с учётом страницы и числа элементов
      // обратно в заголовках мы получаем сколько всего на сервере данных
      // это нужно для вычисления числа страниц с элементами в таблице
      let params = options
        ? {
            page: options.page,
            'per-page': options.itemsPerPage,
          }
        : {}

      return new Promise((resolve, reject) => {
        api
          .get(`${API_REFUND}`, { params })
          .then((resp) => {
            commit(SET_REFUNDS, resp.data)
            commit(SET_REFUNDS_TOTAL, resp.headers['x-pagination-count'])
            resolve(resp)
          })
          .then((err) => {
            reject(err)
          })
      })
    },
    getRefund: (_, refundId) => {
      return new Promise((resolve, reject) => {
        api
          .get(`${API_REFUND}/${refundId}`)
          .then((resp) => {
            resolve(resp)
          })
          .then((err) => {
            reject(err)
          })
      })
    },
    createRefund: (_, refund) => {
      return new Promise((resolve, reject) => {
        api
          .post(`${API_REFUND}`, refund)
          .then((resp) => {
            resolve(resp)
          })
          .then((err) => {
            reject(err)
          })
      })
    },
    updateRefund: ({ commit, dispatch }, updatedRefund) => {
      return new Promise((resolve, reject) => {
        api
          .put(`${API_REFUND}/${updatedRefund.id}`, updatedRefund)
          .then((resp) => {
            commit(SNACK_SUCCESS, 'Рефанд обновлен', { root: true })
            dispatch('getRefunds')
            resolve(resp)
          })
          .then((err) => {
            reject(err)
          })
      })
    },
    deleteRefund: ({ commit, dispatch }, refundId) => {
      return new Promise((resolve, reject) => {
        api
          .delete(`${API_REFUND}/${refundId}`)
          .then((resp) => {
            commit(SNACK_SUCCESS, 'Рефанд удален', { root: true })
            dispatch('getRefunds')
            resolve(resp)
          })
          .then((err) => {
            reject(err)
          })
      })
    },
  },
  mutations: {
    [SET_REFUNDS](state, payload) {
      state.refunds = payload
    },
    [SET_REFUNDS_TOTAL](state, total) {
      state.total = parseInt(total)
    },
  },
  getters: {
    refunds: (state) => state.refunds,
    total: (state) => state.total,
  },
}
