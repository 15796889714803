<template>
  <v-select
    v-model="eventId"
    dense
    hide-details
    clearable
    item-text="name"
    item-value="id"
    :items="events"
    label="Выберите эвент"
    :multiple="multiple"
    @change="$emit('input', eventId)"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('events')
export default {
  name: 'EventSelector',
  props: ['value', 'dense', 'multiple'],
  data() {
    return {
      eventId: this.value,
    }
  },
  computed: {
    ...mapGetters(['events']),
  },
  methods: {
    ...mapActions(['getItems']),
  },
  mounted() {
    this.getItems({ page: 1, itemsPerPage: 100 })
  },
}
</script>

<style></style>
