<template>
  <nav>
    <v-app-bar dense app color="teal darken-4" dark>
      <v-app-bar-nav-icon
        v-if="!side"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-btn icon @click="$router.push('/')"><v-icon>mdi-home</v-icon></v-btn>
      <component :is="bar"></component>

      <div class="ml-auto"></div>
      <v-btn
        v-if="$vuetify.theme.dark"
        icon
        @click="$vuetify.theme.dark = false"
        ><v-icon>mdi-weather-sunny</v-icon></v-btn
      >
      <v-btn v-else icon @click="$vuetify.theme.dark = true"
        ><v-icon>mdi-weather-night</v-icon></v-btn
      >
      <v-btn icon @click="logout"><v-icon dark>mdi-logout</v-icon></v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="!side" v-model="drawer" app>
      <side-menu></side-menu>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import SideMenu from './SideMenu'
import OrdersBar from './OrdersBar'
import EventsBar from './EventsBar'
import DefaultBar from './DefaultBar'

export default {
  name: 'TheNavigation',
  components: {
    SideMenu,
    OrdersBar,
    EventsBar,
    DefaultBar,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    bar() {
      return this.$route.meta.toolbar || 'DefaultBar'
    },
    side() {
      return this.$route.meta.disableSidemenu
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<style lang="scss" scoped></style>
