<template>
  <v-card>
    <v-tabs vertical>
      <template v-for="(option, key1) in options">
        <v-tab :key="`tab${key1}`">
          {{ option.name }}
        </v-tab>
        <v-tab-item :key="`item${key1}`">
          <v-card-text>
            <v-checkbox
              v-for="(value, key2) in option.values"
              :key="key2"
              v-model="options[key1].values[key2].selected"
              :label="value.name"
              @change="$emit('input', options)"
            />
          </v-card-text>
        </v-tab-item>
      </template>
    </v-tabs>
  </v-card>
</template>

<script>
/*selectedOptions[option.name][value.name]*/
import { opencartPublic } from '@/store/helpers/http'
import { API_PRODUCT } from '@/api'

export default {
  name: 'OptionsSelector',
  props: ['id'],
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    opencartPublic.get(`${API_PRODUCT}/${this.id}`).then((resp) => {
      let options = []

      resp.data.data.options.forEach((option) => {
        let values = option.option_value.filter(
          (value) => value.use_for_event === true
        )
        if (values.length > 0) {
          option.option_value = values
          options.push(option)
        }
      })

      this.options = options.map((option) => {
        let { name, option_value } = option

        return {
          name: name,
          values: option_value.map((value) => ({
            name: value.name,
            selected: false,
          })),
        }
      })
    })
  },
}
</script>

<style></style>
