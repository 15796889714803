<template>
  <v-app>
    <the-navigation v-if="navigation"></the-navigation>
    <v-main :class="{ 'login-bg': !navigation }">
      <router-view></router-view>
    </v-main>
    <v-snackbar v-model="snackbar" :color="snackColor" timeout="2000">
      <strong v-html="snackText"></strong>
    </v-snackbar>
  </v-app>
</template>

<script>
import TheNavigation from '@/components/TheNavigation'
import { SNACK_HIDE } from '@/store/mutation-types'

export default {
  name: 'App',
  components: {
    TheNavigation,
  },
  computed: {
    navigation() {
      return !(this.$route.meta.disableNavigation || false)
    },
    snackColor() {
      return this.$store.getters.snackColor
    },
    snackText() {
      return this.$store.getters.snackText
    },
    snackbar: {
      get: function () {
        return this.$store.getters.snackShow
      },
      set: function () {
        this.$store.commit(SNACK_HIDE)
      },
    },
  },
}
</script>

<style scoped>
.login-bg {
  background: url('/bg.jpg') 50% 50% no-repeat;
  background-size: cover;
}
</style>
