<template>
  <v-card class="d-flex flex-column justify-space-between flex-grow-1">
    <v-card-title
      ><v-icon class="mr-2">{{ icon }}</v-icon> {{ title }}</v-card-title
    >
    <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
    <v-card-actions>
      <v-btn color="primary" :to="link">{{ button }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PanelItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    button: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped></style>
