import axios from 'axios'
import store from '@/store/index'
import { SET_AUTH_ERROR, SNACK_ERROR } from '@/store/mutation-types'
import router from '@/router/index'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

const opencart = axios.create({
  baseURL: process.env.VUE_APP_OPENCART_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

const opencartPublic = axios.create({
  baseURL: process.env.VUE_APP_OPENCART_PUBLIC_URL,
  headers: {
    'Content-type': 'application/json',
  },
})

api.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (err.response.status === 401 || err.response.status === 403) {
      store.commit(SET_AUTH_ERROR)
      store.commit(SNACK_ERROR, 'Требуется авторизация')
      router.push({ name: 'login' })
      return Promise.reject(err)
    } else {
      let errText = err.response.data.message || err.response.data[0].message
      store.commit(SNACK_ERROR, errText)
      return Promise.reject(err)
    }
  }
)

opencart.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

opencart.interceptors.response.use(
  (resp) => resp,
  async function (err) {
    if (err.response.status === 401 || err.response.status === 403) {
      // пробуем обновить токен втихую, если это ошибка авторизации
      await store.dispatch('renewToken')
    } else {
      return Promise.reject(err)
    }
  }
)

export { api, opencart, opencartPublic }
