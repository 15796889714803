<template>
  <div>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="opts"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [25, 50, 100],
        }"
        @update:options="$emit('update:options', opts)"
      >
        <template v-slot:item.actions="{ item }">
          <nobr>
            <v-btn icon @click="$emit('edit', item.id)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn icon @click="$emit('delete', item.id)"
              ><v-icon>mdi-trash-can-outline</v-icon></v-btn
            >
          </nobr>
        </template>

        <template v-slot:item.active="{ item }">
          <v-icon :color="item.active ? 'green' : 'red'">mdi-record</v-icon>
        </template>

        <template v-slot:item.boosterOnline="{ item }">
          <v-icon :color="item.boosterOnline ? 'green' : 'red'"
            >mdi-record</v-icon
          >
        </template>

        <template v-slot:item.customerOnline="{ item }">
          <v-icon :color="item.customerOnline ? 'green' : 'red'"
            >mdi-record</v-icon
          >
        </template>
      </v-data-table>
    </v-container>

    <v-btn
      v-if="!hideNew"
      color="red"
      dark
      bottom
      right
      fab
      fixed
      @click="$emit('create')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['title', 'headers', 'items', 'options', 'total', 'hideNew'],
  data() {
    return {
      opts: this.options,
    }
  },
}
</script>

<style></style>
