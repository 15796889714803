<template>
  <v-container fluid>
    <v-row>
      <v-col md="2">
        <trust-box />
      </v-col>
      <v-col md="10">
        <v-data-table
          :headers="headers"
          :items="formattedOrders"
          :options.sync="options"
          :server-items-length="totalOrders"
          :loading="loading"
          dense
          :footer-props="{
            'items-per-page-options': [25, 50, 100],
          }"
        >
          <template #item="{ item }">
            <tr
              v-for="(product, key) in item.products"
              :key="product.id"
              :class="[
                'text-xs',
                item.status,
                item.inBlacklist !== undefined ? 'blacklist' : '',
              ]"
            >
              <td v-if="key === 0" valign="top" :rowspan="item.products.length">
                {{ item.id }}
              </td>
              <td v-if="key === 0" valign="top" :rowspan="item.products.length">
                {{ item.datetime }}
              </td>
              <td v-if="key === 0" valign="top" :rowspan="item.products.length">
                <div class="d-flex align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-if="item.status === 'New Order'"
                        width="24"
                        height="24"
                        src="/9-13-loader.gif"
                        title="New Order"
                      />
                      <v-icon
                        v-else
                        :class="{ blink_me: item.status === 'Disputed' }"
                        :color="mapStatusColor(item.status)"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-record
                      </v-icon>
                    </template>
                    <span> {{ item.status }}</span>
                  </v-tooltip>

                  <v-tooltip
                    v-for="coupon in item.coupons"
                    :key="coupon.code"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        color="default"
                        v-bind="attrs"
                        class="ml-1"
                        v-on="on"
                      >
                        mdi-brightness-percent
                      </v-icon>
                    </template>
                    <span>
                      Код: {{ coupon.code }} <br />
                      Скидка: {{ coupon.amount }} {{ item.currency }}</span
                    >
                  </v-tooltip>
                </div>
              </td>
              <td v-if="key === 0" valign="top" :rowspan="item.products.length">
                <img
                  width="24"
                  height="24"
                  :title="item.payment_gate"
                  :src="`/${mapPaymentIcons(item.payment_gate)}`"
                />
              </td>
              <td valign="top">
                <span
                  class="copy-to-clipboard"
                  @click="copyToClipboard(item.buyer)"
                  v-html="item.buyer"
                >
                </span>
                <v-tooltip v-if="item.inBlacklist !== undefined" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="red"
                      dark
                      small
                      v-bind="attrs"
                      class="ml-2"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span
                    >Покупатель в чёрном списке:<br />
                    {{ item.inBlacklist.comment }}</span
                  >
                </v-tooltip>
              </td>
              <td valign="top" v-html="product.externalId"></td>
              <td valign="top">
                <nobr>
                  <img
                    width="24"
                    height="24"
                    :title="product.game"
                    :alt="product.game"
                    :src="`/${mapGameIcons(product.game)}`"
                  />
                  <img
                    width="24"
                    height="24"
                    :title="product.region"
                    :alt="product.region"
                    :src="`/${mapRegionIcons(product.region)}`"
                  />
                </nobr>
              </td>
              <td valign="top">
                <div
                  class="copy-to-clipboard"
                  @click="copyToClipboard(product.details, item.armory)"
                  v-html="product.text"
                ></div>
              </td>
              <td valign="top">
                {{ product.quantity }}
              </td>
              <td valign="top">
                {{ product.eventTime }}
              </td>
              <td valign="top">
                <booster-select
                  :value="product.booster"
                  :items="boosters"
                  hide-add="true"
                  hide-id="true"
                  dont-get="true"
                  @change="setBooster($event, product, item.id)"
                >
                </booster-select>
              </td>
              <td valign="top">
                <div class="d-flex space-between justify-end">
                  <v-btn
                    v-if="!product.completed"
                    icon
                    color="green"
                    @click="setComplete(product, item)"
                    ><v-icon>mdi-check-bold</v-icon></v-btn
                  >
                  <v-icon v-else color="primary">mdi-star-box</v-icon>

                  <v-btn
                    v-if="!product.refunded"
                    icon
                    color="default"
                    @click="editRefund(item, product)"
                  >
                    <v-icon>mdi-cash-refund</v-icon>
                  </v-btn>
                  <v-icon v-else color="primary">mdi-cash-check</v-icon>

                  <v-btn
                    icon
                    :disabled="product.booster === 0"
                    :color="
                      product.ccc
                        ? product.ccc.active === 1
                          ? 'green'
                          : 'red'
                        : product.booster > 0
                        ? 'default'
                        : 'grey lighten-1'
                    "
                    @click="doHmb(product)"
                  >
                    <v-icon>mdi-key</v-icon>
                  </v-btn>
                </div>
              </td>
              <td v-if="key === 0" valign="top" :rowspan="item.products.length">
                <div v-if="key === 0" class="d-flex space-between">
                  <v-btn icon target="_blank" :href="item.armory"
                    ><v-icon>mdi-link</v-icon></v-btn
                  >

                  <v-btn
                    v-if="item.telephone.indexOf('skype:') === 0"
                    icon
                    color="blue"
                    target="_blank"
                    @click="$clipboard(item.telephone.replace('skype:', ''))"
                    ><v-icon>mdi-skype</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    icon
                    color="purple"
                    target="_blank"
                    @click="$clipboard(item.telephone.replace('discord:', ''))"
                  >
                    <img
                      width="20"
                      height="20"
                      alt="discord"
                      src="@/assets/discord-icon.svg"
                    />
                  </v-btn>
                  <v-btn
                    icon
                    color="red"
                    :href="'mailto:' + item.email"
                    target="_blank"
                    ><v-icon>mdi-gmail</v-icon></v-btn
                  >
                  <v-btn icon color="orange" @click="editOrder(item)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                </div>
              </td>
              <td v-if="key === 0" valign="top" :rowspan="item.products.length">
                <v-btn
                  v-if="!item.comment.length"
                  x-small
                  color="gray"
                  link
                  depressed
                  @click="editOrder(item)"
                >
                  Добавить
                </v-btn>
                <span
                  v-else
                  class="comment-row"
                  @click="editOrder(item)"
                  v-html="item.comment"
                >
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="800">
      <v-card>
        <form @submit.prevent="saveOrder()">
          <v-card-title class="teal darken-4 white--text">
            Заказ №{{ order.order_id }}
            <small class="ml-auto mr-auto">IP: {{ order.ip }}</small>
            <v-btn icon depressed color="white" @click="dialog = !dialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>

          <v-card-text class="mt-5">
            <v-alert
              v-if="order.inBlacklist"
              color="orange"
              dismissible
              text
              type="info"
              >В чёрном списке: {{ order.inBlacklist.comment }}</v-alert
            >

            <v-select
              v-model="order.order_status_id"
              label="Статус"
              :items="orderStatuses"
            />

            <v-row>
              <v-col>
                <v-text-field
                  v-model="order.firstname"
                  label="Character Name"
                />
              </v-col>
              <v-col>
                <v-text-field v-model="order.lastname" label="Server Name" />
              </v-col>
              <v-col v-for="field in customFields" :key="field.id">
                <v-select
                  v-if="field.type == 'select'"
                  v-model="order.custom_field[field.id]"
                  :label="field.name"
                  :items="field.values"
                />
                <v-text-field
                  v-else
                  v-model="order.custom_field[field.id]"
                  :label="field.name"
                />
              </v-col>
            </v-row>

            <v-textarea v-model="order.comment" label="Комментарий" />

            <h4>Контактная информация:</h4>

            <v-row>
              <v-col>
                <v-text-field v-model="order.telephone" label="Skype/Discord" />
              </v-col>
              <v-col>
                <v-text-field v-model="order.email" label="Email" />
              </v-col>
            </v-row>

            <h4>Платёжная информация ({{ order.payment_code }}):</h4>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="order.payment_firstname"
                  label="Имя покупателя"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="order.payment_lastname"
                  label="Фамилия"
                />
              </v-col>
              <v-col>
                <v-text-field label="Комиссия Pay Pal" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Pay pal email" />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="order.total"
                  label="Сумма заказа c учётом скидки"
                  readonly
                  :suffix="order.currency_code"
                />
              </v-col>
              <v-col>
                <v-text-field
                  :value="discountTotal"
                  readonly
                  label="Сумма скидки"
                  :suffix="order.currency_code"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex">
            <v-btn color="primary" type="submit">Сохранить</v-btn>
            <v-btn @click="dialog = !dialog">Закрыть</v-btn>
            <v-btn class="ml-auto" @click="blDialog = true"
              ><v-icon>mdi-plus</v-icon> Добавить в чёрный список</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="blDialog" width="300">
      <v-card>
        <v-form @submit.prevent="addToBlacklist()">
          <v-card-title class="grey lighten-2">
            Добавить покупателя в чёрный список
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="blComment"
              label="Причина добавления в чёрный список"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit">Добавить</v-btn>
            <v-btn @click="blDialog = !blDialog">Отмена</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="refundDialog"
      max-width="800px"
      @click:outside="closeRefund"
    >
      <v-card>
        <form @submit.prevent="saveRefund">
          <v-card-title class="teal darken-4 white--text">
            Создать рефанд
            <v-btn icon depressed color="white" @click="closeRefund">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-textarea v-model="refund.comment" label="Причина рефанда" />

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="refund.sum"
                  label="Сумма рефанда"
                  :suffix="refund.currency"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="refund.currency"
                  label="Валюта"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="refund.refundScreen"
                  label="Скриншот рефанда"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="refund.userScreen"
                  label="Скриншот переписки"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-btn color="primary" type="submit">Создать</v-btn>
            <v-btn @click="closeRefund">Закрыть</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>

    <payout-dialog
      :key="payout.orderItemId"
      v-model="payoutDialog"
      :item="payout"
      no-close="true"
    />

    <hmb-dialog
      v-model="cccDialog"
      :item="ccc"
      @toggle-activity="toggleHmbActivity"
    />
  </v-container>
</template>

<script>
import BoosterSelect from '@/components/BoosterSelect'
import PayoutDialog from '@/components/payouts/PayoutDialog'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('orders')
import { SNACK_SUCCESS } from '@/store/mutation-types'
import {
  fromDBtoCET,
  fromDBtoEST,
  DATETIME_FORMAT,
  DATETIME_DB_FORMAT,
} from '@/helpers/dates'
import TrustBox from '../components/TrustBox.vue'
import HmbDialog from '../components/hmb/HmbDialog.vue'

export default {
  name: 'Orders',
  components: {
    PayoutDialog,
    TrustBox,
    HmbDialog,
    BoosterSelect,
  },
  data() {
    return {
      beep: new Audio('/beep.wav'),
      interval: null,
      ccc: {},
      cccDialog: false,
      order: {
        ip: '192.168.1.1',
        order_id: 3,
        order_status_id: 7,
        comment: '',
        total: 0,
        firstname: '',
        lastname: '',
        payment_firstname: '',
        payment_lastname: '',
        telephone: '',
        email: '',
        custom_field: {},
      },
      payoutDialog: false,
      payout: {
        orderId: 0,
        orderItemId: 0,
        boosterId: 0,
        status: false,
        screenshot: '',
        payAmount: 0,
        payCurrency: 'USD',
        externalId: '',
      },
      refund: {
        orderId: '',
        orderItemId: '',
        currency: 'USD',
        sum: '0',
        refundScreen: '',
        userScreen: '',
        comment: '',
      },
      paymentIcons: [
        { name: 'pp_standard', icon: 'paypal.png' },
        { name: 'stripe', icon: 'stripe.png' },
        { name: 'advcash', icon: 'advcash.png' },
      ],
      gameIcons: [
        { game: 'WoW TWW', icon: 'WoW SL.png' },
        { game: 'WoW', icon: 'WoW.webp' },
        { game: 'WoW BC', icon: 'WoW BC.png' },
        { game: 'Destiny', icon: 'Destiny.jpg' },
        { game: '', icon: 'Destiny.jpg' },
        { game: 'Diablo 4', icon: 'Diablo4.jpg'}
      ],
      regionIcons: [
        { region: 'EU', icon: '001-european-union.svg' },
        { region: 'US', icon: '002-united-states.svg' },
      ],
      statusColor: [
        {
          text: 'New Order',
          color: 'blue',
        },
        {
          text: 'New Order (slot problem)',
          color: 'blue',
        },
        {
          text: 'In Progress',
          color: 'yellow darken-1',
        },
        {
          text: 'Claim',
          color: 'orange',
        },
        {
          text: 'Completed',
          color: 'green',
        },
        {
          text: 'Pending Payment',
          color: 'grey',
        },
        {
          text: 'Payment Failed',
          color: 'red',
        },
        {
          text: 'Refunded',
          color: 'red',
        },
        {
          text: 'Suspicious',
          color: 'pink',
        },
        {
          text: 'Disputed',
          color: 'red',
          blink: true,
        },
      ],
      blComment: '',
      blDialog: false,
      refundDialog: false,
      dialog: false,
      headers: [
        { text: '№', value: 'id' },
        { text: 'Дата и время', value: 'datetime' },
        { text: 'Статус', value: 'status' },
        { text: 'PayGate', value: 'payment_gate' },
        { text: 'Buyer', value: 'buyer' },
        { text: 'Order number', value: 'externalId' },
        { text: 'Game', value: 'game' },
        { text: 'Состав заказа', value: 'orderInfo' },
        { text: 'Кол-во', value: 'quantity' },
        { text: 'Рейд', value: 'eventTime' },
        { text: 'Бустер', value: 'booster' },
        { text: 'Действия', value: 'actions' },
        { text: '', value: 'actions2' },
        { text: 'Коментарий', value: 'comment' },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'formattedOrders',
      'orderStatuses',
      'filterOptions',
      'customFields',
      'totalOrders',
      'searchString',
      'loading',
    ]),
    options: {
      set(value) {
        this.setFilterOptions(value)
      },
      get() {
        return this.filterOptions
      },
    },
    discountTotal() {
      let total = 0
      if (this.order.coupons)
        this.order.coupons.forEach((c) => {
          total += parseInt(c.amount)
        })
      return total
    },
    boosters() {
      return this.$store.getters['booster/boosters'].map((el) => ({
        text: el.name,
        value: el.id,
      }))
    },
  },
  methods: {
    ...mapActions([
      'getOrders',
      'getStoreInfo',
      'getOrder',
      'getOrdersByStatus',
      'setFilterOptions',
      'getNewOrders',
      'getCustomFields',
      'updateOrder',
      'updateOrderItem',
    ]),
    doHmb({ ccc, externalId: jobId, booster: boosterId }) {
      this.cccDialog = true

      if (ccc) {
        this.ccc = ccc
      } else {
        this.$store
          .dispatch('hmb/addItem', { jobId, boosterId })
          .then((resp) => {
            this.$store.commit(SNACK_SUCCESS, 'Код сгенерирован')
            this.ccc = resp.data
          })
      }
    },
    toggleHmbActivity(item) {
      this.$store.dispatch('hmb/toggleActive', item)
    },
    mapStatusColor(statusText) {
      const status = this.statusColor.find(({ text }) => text === statusText)
      return status ? status.color : 'gray'
    },
    mapGameIcons(gameName) {
      const icon = this.gameIcons.find(({ game }) => game === gameName)
      return icon ? icon.icon : 'gamingcy.svg'
    },
    mapRegionIcons(regionName) {
      const icon = this.regionIcons.find(({ region }) => region === regionName)
      return icon ? icon.icon : 'gamingcy.svg'
    },
    mapPaymentIcons(code) {
      const icon = this.paymentIcons.find(({ name }) => name === code)
      return icon ? icon.icon : 'gamingcy.svg'
    },
    copyToClipboard(text, armory) {
      if (armory) text += `\n${armory}`
      let m = text.match(/EventId:([0-9]+)/)

      let r = text.match(/Region:(US|EU)/)

      if (m && m.length > 1) {
        this.$store
          .dispatch('calendar/getItem', parseInt(m[1]))
          .then((resp) => {
            let time = ''
            if (r && r.length > 1 && r[1] == 'EU') {
              time = fromDBtoCET(resp.data.time, DATETIME_FORMAT) + ' CET'
            } else {
              time = fromDBtoEST(resp.data.time, DATETIME_DB_FORMAT) + ' EST'
            }
            let newtext = text.replace(/EventId:[0-9]+/, `(${time})`)
            this.$clipboard(newtext)
            this.copied({ value: newtext })
          })
      } else {
        this.$clipboard(text)
        this.copied({ value: text })
      }
    },
    setComplete(item, order) {
      if (window.confirm('Вы уверены?')) {
        this.editPayout(item, order)

        this.updateOrderItem({
          item: {
            completed: true,
          },
          itemId: item.id,
          orderId: order.id,
        }).then(() => {
          this.$store.commit(SNACK_SUCCESS, 'Обновлено')
        })
      }
    },
    setRefunded(itemId, orderId) {
      this.updateOrderItem({
        item: {
          refunded: true,
        },
        itemId,
        orderId,
      })
    },
    setBooster(boosterId, item, orderId) {
      this.updateOrderItem({
        item: {
          boosterId: boosterId,
        },
        itemId: item.id,
        orderId: orderId,
      }).then(() => {
        this.$store.commit(SNACK_SUCCESS, 'Обновлено')
      })
    },
    copied({ value }) {
      this.$store.commit(SNACK_SUCCESS, `Скопировано "${value}"`)
    },
    editOrder(item) {
      this.getOrder(item.id).then((resp) => {
        this.order = resp.data.data
        //если там будет null, то всё сломается
        if (!this.order.custom_field) {
          this.order.custom_field = {}
        }
        this.order.order_status_id = parseInt(this.order.order_status_id)
        this.order.inBlacklist = item.inBlacklist
        this.dialog = true
      })
    },
    editRefund(item, product) {
      // тут нам не надо запрашивать заказ заново
      // вся информация уже есть в таблице
      // см. объекты item и produt.
      this.refund.orderId = item.id
      this.refund.orderItemId = product.id
      this.refund.currency = product.currency
      this.refund.sum = product.total
      this.refundDialog = true
    },
    closeRefund() {
      this.$emit('input', false)

      this.refund = {
        orderId: '',
        orderItemId: '',
        currency: 'USD',
        sum: '0',
        refundScreen: '',
        userScreen: '',
        comment: '',
      }

      this.refundDialog = false
    },
    saveRefund() {
      const newRefund = {
        orderId: parseInt(this.refund.orderId),
        orderItemId: this.refund.orderItemId,
        refundSum: parseFloat(this.refund.sum),
        refundCurrency: this.refund.currency,
        status: false,
        refundScreen: this.refund.refundScreen,
        userScreen: this.refund.userScreen,
        comment: this.refund.comment,
        userId: 1,
      }

      this.$store.dispatch('refunds/createRefund', newRefund).then(() => {
        this.$store.commit(SNACK_SUCCESS, 'Refund создан')

        this.setRefunded(newRefund.orderItemId, newRefund.orderId)
        this.closeRefund()
      })
    },
    saveOrder() {
      let {
        order_id,
        order_status_id,
        firstname,
        lastname,
        email,
        telephone,
        comment,
        custom_field,
      } = this.order

      custom_field = {
        account: custom_field,
      }

      let status = order_status_id

      this.updateOrder({
        order_id,
        status,
        firstname,
        lastname,
        email,
        telephone,
        comment,
        custom_field,
      }).then(() => {
        this.getOrders()
        this.$store.commit(
          SNACK_SUCCESS,
          `Заказ ${this.order.order_id} обновлён`
        )
      })
      this.dialog = false
    },
    addToBlacklist() {
      this.$store
        .dispatch('blacklist/addItem', {
          email: this.order.email,
          characterName: this.order.firstname,
          messenger: this.order.telephone,
          comment: this.blComment,
        })
        .then(() => {
          this.blComment = ''
          this.blDialog = false
          this.$store.commit(
            SNACK_SUCCESS,
            `Покупатель ${this.order.email} добавлен в чёрный список!`
          )
        })
    },
    editPayout(product, order) {
      this.payout.boosterId = product.booster
      this.payout.orderId = order.id
      this.payout.externalId = product.externalId
      this.payout.orderItemId = product.id
      this.payout.payCurrency = product.currency
      this.payout.payAmount = product.total
      this.payoutDialog = true
    },
  },
  watch: {
    options: function () {
      this.setFilterOptions(this.options)
      this.getOrders()
    },
  },
  async mounted() {
    await this.getStoreInfo()
    await this.$store.dispatch('booster/getItems')
    await this.$store.dispatch('hmb/getItems')
    this.getOrders()
    this.getCustomFields()
    this.interval = setInterval(() => {
      if (this.searchString.length === 0)
        this.getNewOrders().then((resp) => {
          if (resp.data.data.length) this.beep.play()
        })
    }, 60 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>

<style lang="scss">
.hl {
  background-color: #fff3e0;
  font-weight: bold;
  color: orangered;
}
.v-data-table table td {
  padding: 5px !important;
}
.Completed {
  background: rgba(0, 255, 0, 0.15);
  &:hover {
    background: rgba(0, 255, 0, 0.1) !important;
  }
}
.Refunded,
.Disputed {
  background: rgba(255, 0, 0, 0.15);
  &:hover {
    background: rgba(255, 0, 0, 0.1) !important;
  }
}
.Suspicious,
.blacklist {
  background: rgba(255, 0, 170, 0.15);
  &:hover {
    background: rgba(255, 0, 170, 0.1) !important;
  }
}
.Claim {
  background: rgba(255, 123, 0, 0.15);
  &:hover {
    background: rgba(255, 123, 0, 0.1) !important;
  }
}
.small-select {
  width: 100px;
  font-size: 0.9em;
}
.copy-to-clipboard {
  display: inline;
  cursor: pointer;
  border-bottom: 1px dashed dodgerblue;
  font-size: 0.9em;
}
.comment-row {
  cursor: pointer;
  border-bottom: 1px dashed dodgerblue;
  font-size: 0.9em;
}
.text-xs {
  font-size: 0.9em;
}
.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>
